import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Paper, CircularProgress, Typography, Button, useMediaQuery, Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LayersIcon from '@mui/icons-material/Layers';

import {toLonLat} from 'ol/proj';

import HorizontalSlider from '../ui-controls/horizontal-slide';
import WWAFeatureInfo from './wwa-feature-info';
import MRMSFeatureInfo from './mrms-feature-info';
import MRMSQPEFeatureInfo from './mrms-qpe-feature-info';
import TropicalCyclonesFeatureInfo from './tropical-cyclones-feature-info';
import NBSFeatureInfo from './nbs-feature-info';
import NDFDFeatureInfo from './ndfd-feature-info';
import STOFSFeatureInfo from './stofs-feature-info';
import SSTFeatureInfo from './sst-feature-info';
import OFSFeatureInfo from './ofs-surface-currents-feature-info.js';
import SatelliteFeatureInfo from './satellite-feature-info.js';
import S100FeatureInfo from './s100-feature-info';
import FederalBoundariesFeatureInfo from './federal-boundaries-feature-info.js';
import ZoneForecastsFeatureInfo from './zone-forecasts-feature-info.js';
import PathogenFeatureInfo from './pathogen-feature-info.js';
import LightningDensityFeatureInfo from './ltng-den-feature-info.js';
import ConvectiveFeatureInfo from './convective-feature-info.js';
import SurfaceObsFeatureInfo from './surface-obs-feature-info.js';

import PointForecast from './point-forecast';
import useDrawDot from './hooks/use-draw-dot';

import { OL_ZINDEXES } from '../../config';
import { getTimeZone, shortDateString } from '../../utilities/utilities.js';

// Map valid layer group names to the names used for their tabs
const TAB_LABELS = {
    wwa: 'Alerts',
    point_forecast: 'Point Forecasts', // Not a layer group but set the label here for consistency
    zone_forecasts: 'Zone Forecasts',
    ndfd: 'Gridded Forecasts',
    mrms: 'Radar',
    mrms_qpe: 'QPE',
    tropical_cyclones: 'Tropical Cyclones',
    s111: 'Currents',
    stofs: 'Flooding',
    nbs: 'BlueTopo',
    satellite: 'Satellite',
    sst: 'SST',
    s100: 'S100',
    federal_agency_boundaries: 'Boundaries',
    pathogen: 'Pathogen',
    ltng_den: 'Lightning',
    convective_outlooks: 'Convective',
    surface_obs: 'Observations'
};


const sxStyles = {
    mapClickPopupContainer: {
        padding: '5px 10px',
        position: {
            sm: 'absolute',
            xs: 'fixed',
        },
        right: {
            sm: '1em',
            xs: 'auto',
        },
        top: {
            sm: '2.75em',
            xs: 'auto',
        },
        bottom: {
            sm: 'auto',
            xs: '0em',
        },
        width: {
            lg: '25em',
            sm: '23em',
            xs: 'calc(100% - 20px)', //subtract padding
        },
        maxHeight: {
            xl: 'calc(100vh - 12em)',
            sm: 'calc(100vh - 8em)',
            xs: '60vh',
        },
    },
    popupCloseButton : {
        backgroundColor: 'rgba(255,255,255,0)',
        '&:hover': {
            color: 'primary.main'
        },
        position: 'absolute',
        top: '0.3em',
        right: '.5em',
        height: '2em',
        width: '2em',
    },
    popupLayersButton : {
        color:'primary.main',
        backgroundColor: 'rgba(255,255,255,0)',
        '&:hover': {
            color: 'primary.main'
        },
        position: 'absolute',
        top: '0.3em',
        right: '2.5em',
        height: '2em',
        width: '2em',
    },
    bottomCloseLink: {
    margin: '5px 5px 0px 5px',
    fontSize: '0.8em',
    color: '#555',
    userSelect: 'none',
    '&:hover': {
            color: 'primary.main',
            cursor: 'pointer',
        },
    },
    popupNavButton : {
        backgroundColor: '#eee',
        color: 'primary.main',
        '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
        },
        borderRadius: '25px',
        margin: '4px 4px 4px 4px',
    },
};

const classes = {
    headerContainer: {
        position: 'relative',
    },
    mapClickNavBar: {
        overflowX: 'hidden',
        '&:active:hover': {
            cursor: 'grabbing',
        },
        maxHeight: '6.25em'
    },
    popupContentContainer: {
        overflow: 'auto',
    },
    pointIcon: {
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        display: "inline-block",
        backgroundColor: 'rgba(255,255,0,1.0)',
        border: '2px solid #005194',
        position: 'relative',
        top: '3px',
        right: '0.25em',
    },
    divider: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginLeft: 10,
        marginRight: 10,
    },
    blueDivider: {
        borderBottom: '1px solid #005194',
        marginLeft: 10,
        marginRight: 10,
    },
};

/**
* MapClickPopup Component used to display feature info for all layers that are on when map is clicked
*
* @prop (obj) map - OL map object used for drawing
* @prop (obj) mapClickInfo - feature info for point that was clicked
* @prop (obj) mapClickCoords - stores x and y coordinates at keys 'x' and 'y'
* @prop (func) setLayerMenuOn  - callback for updating layer menu open/closed
* @prop (func) setLegendMenuOn - callback for updating legend menu open/closed
* @prop (str) popupPrevMenuState - Strong containing "layer", "legend", or "none" to indicate state of map menus
*                                  before map click popup was turned on (used to identify action to take to restore map
*                                  map to previous state when popup is closed)
* @prop (obj) productToggles - maps product names to their toggle state (true/false for on/off)
* @prop (obj) styleInfo - contains info about styles/legends parsed from capabilities
* @prop (obj) olLayerState - maps ol layer names to obj containing on, layerParams, styleParams, and currentSources
**/
function MapClickPopup(props) {
    const theme = useTheme();
    const closeButtonHeight = useRef();

    // currentTab dictates which nav bar button to highlight and which content to display
    const [currentTab, setCurrentTab] = useState(null);
    const [clearDotFeature, drawDotFeature] = useDrawDot(props.map, OL_ZINDEXES.map_click_dot_layer);

    const [isCellphone, setIsCellphone] = useState(useMediaQuery(theme.breakpoints.only('xs')));

    /*
    const restoreMapMenuState = (prevMenuState) => {
        // Do no reopen any menus no matter what for cellphones in order to keep map clear
        if (isCellphone) return;

        if (prevMenuState === "layer") {
            props.setLayerMenuOn(true);
        } else if (prevMenuState === "legend") {
            props.setLegendMenuOn(true);
        }
        return; // take no action if "none" since these menus must already be off is popup is open
    }
    */

//    const prevCoords = useRef({});
//
//    useEffect(() => {
//        prevCoords.current = {x: props.mapClickCoords.x , y: props.mapClickCoords.y};
//    }, [props.mapClickCoords]);

    // Validate mapClickInfo (its data's coords must match the latest clicked coords)
    let mapClickInfoValid = false;
    for (const key in props.mapClickInfo) {
        if (props.mapClickCoords.x === props.mapClickInfo[key].coords.x
            && props.mapClickCoords.y === props.mapClickInfo[key].coords.y) {
                mapClickInfoValid = true;
        }
    }

    // Handle drawing of map click dot
    clearDotFeature();
    drawDotFeature([props.mapClickCoords.x, props.mapClickCoords.y]);

    // Set default tab: Usually WWA has precedence if it is on, otherwise default to point forecasts.
    // The exception is if one of the mutually exclusive products are toggled on (s100, nbs, stofs, sst, mrms_qpe). In that case
    // whichever one is on will be the default
    if((Object.keys(props.mapClickInfo).length > 0) && (currentTab === null || !(currentTab in props.mapClickInfo))) {
        if ("s100" in props.mapClickInfo && props.productToggles.s100) {
            setCurrentTab("s100");
        } else if ("nbs" in props.mapClickInfo && props.productToggles.nbs) {
            setCurrentTab("nbs");
        } else if ("stofs" in props.mapClickInfo && props.productToggles.stofs) {
            setCurrentTab("stofs");
        } else if ("sst" in props.mapClickInfo && props.productToggles.sst) {
            setCurrentTab("sst");
        } else if ("wwa" in props.mapClickInfo) {
            setCurrentTab("wwa");
        } else if ("mrms_qpe" in props.mapClickInfo && props.productToggles.mrms_qpe) {
            // Placed after wwa despite this being a layer that turns off all other layers because it is a common use
            // case to turn on wwa with qpe to look at flood warnings
            setCurrentTab("mrms_qpe");
        } else {
            setCurrentTab("point_forecast");
        }
    }

    const buttonTextStyle = {whiteSpace: 'nowrap', fontSize:  useMediaQuery(theme.breakpoints.down('xs')) ? "75%" : "80%" };

//    // DEBUG
//    console.log("> MapClickInfo is:");
//    console.log(props.mapClickInfo);

    // Generate content to render in popup
    const navBarContent = Object.keys(props.mapClickInfo).map((layer_group, index) => {
        if (layer_group === "point_forecast" || layer_group === "wwa" || layer_group === "zone_forecasts" || layer_group === "ndfd") return;
        return (
            <Grid item key={index}>
                <Button
                    size="small"
                    onClick={() => {setCurrentTab(layer_group)}}
                    sx={{
                        ...sxStyles.popupNavButton,
                        backgroundColor: (currentTab === layer_group) ? 'primary.main' : '#eee',
                        color: (currentTab === layer_group) ? 'primary.contrastText' : "",
                    }}
                >
                    <Typography noWrap variant="caption" sx={buttonTextStyle}>{(TAB_LABELS[layer_group]) ? TAB_LABELS[layer_group] : layer_group}</Typography>
                </Button>
            </Grid>
        );
    });

    let popupContent = <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress sx={{margin: '70px 20px'}} /></div>;

    if ((mapClickInfoValid === true) && (currentTab in props.mapClickInfo) && (Object.keys(props.mapClickInfo[currentTab].data).length > 0)) {
         // Safe to try rendering content
        switch(currentTab) {
        case "wwa":
            popupContent = <WWAFeatureInfo data={props.mapClickInfo[currentTab].data} map={props.map} />;
            break;
        case "zone_forecasts":
            popupContent = <ZoneForecastsFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                map={props.map}
                olLayerState={props.olLayerState}
            />;
            break;
        case "mrms":
            popupContent = <MRMSFeatureInfo data={props.mapClickInfo[currentTab].data} styleInfo={props.styleInfo.mrms} />;
            break;
        case "mrms_qpe":
            popupContent = <MRMSQPEFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                styleInfo={props.styleInfo.mrms_qpe}
                olLayerState={props.olLayerState}
            />;
            break;
        case "point_forecast":
            popupContent = <PointForecast data={props.mapClickInfo[currentTab].data} />;
            break;
        case "tropical_cyclones":
            popupContent = <TropicalCyclonesFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                map={props.map}
                styleInfo={props.styleInfo.tropical_cyclones}
                activeTropicalSSLayer={props.olLayerState['tropical_ss'].layersParam}
            />;
            break;
        case "stofs":
            popupContent = <STOFSFeatureInfo data={props.mapClickInfo[currentTab].data} styleInfo={props.styleInfo.stofs}/>;
            break;
        case "sst":
            popupContent = <SSTFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                urls={props.mapClickInfo[currentTab].urls}
                styleInfo={props.styleInfo.sst}
                olLayerState={props.olLayerState}
            />;
            break;
        case "satellite":
            const varName = props.olLayerState['satellite'].layersParam
            popupContent = <SatelliteFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                activeVariable={varName}
            />;
            break;
        case "ndfd":
            const variableName = props.olLayerState['ndfd'].currentSource
            popupContent = <NDFDFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                activeVariable={variableName}
                styleInfo={props.styleInfo.ndfd}
            />;
            break;
        case "s111":
            popupContent = <OFSFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                styleInfo={props.styleInfo.s111}
                olLayerState={props.olLayerState}
            />;
            break;
        case "nbs":
            popupContent = <NBSFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                map={props.map} />;
            break;
        case "s100":
            popupContent = <S100FeatureInfo
                data={props.mapClickInfo[currentTab].data}
                styleInfo={props.styleInfo.s100}
                map={props.map}
                olLayerState={props.olLayerState}
            />;
            break;
        case "federal_agency_boundaries":
            popupContent = <FederalBoundariesFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                map={props.map}
                olLayerState={props.olLayerState}
            />;
            break;
        case "pathogen":
            popupContent = <PathogenFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                styleInfo={props.styleInfo.pathogen}
            />;
            break;
        case "ltng_den":
            popupContent = <LightningDensityFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                styleInfo={props.styleInfo.ltng_den}
            />;
            break;
        case "convective_outlooks":
            popupContent = <ConvectiveFeatureInfo
                data={props.mapClickInfo[currentTab].data}
                map={props.map}
                olLayerState={props.olLayerState}
            />;
            break;
        case "surface_obs":
            popupContent = <SurfaceObsFeatureInfo
                data={props.mapClickInfo[currentTab].data}
            />;
            break;
        default:
            popupContent = <div><p>No feature info available for this service.</p></div>;
            break;
        }
    }

// Remove the ref in close button if removing this code
//    const headerStyle = () => {
//        if (closeButtonHeight.current && closeButtonHeight.current.offsetHeight) {
//            return({
//                height: closeButtonHeight.current.offsetHeight + 4,
//                paddingTop: '1em',
//            });
//        } else {
//            return({});
//        }
//    };

    // Adjust size of popup content container if using cellphone
    // Note: The popupContentContainer holds content of the popup (everything but the navbar and/or footer of the popup)
    // It is intended to conform to a max height and create a scroll bar when the content exceeds that
    // max height. The header and footer are intended to always display (however the height of the header can vary
    // depending on the number of buttons in it (Max of three rows). After that it will scroll. So potential variation
    // needs to be taken into account when setting this height). Finally, the most important thing is that this height
    // needs to work in conjunction with the height of the mapClickPopupContainer which is also defined in this file.
    // Currently have mapClickPopupContainer at 55vh for cellphone and popupContentContainer at 'calc(50vh - 8em)'
    // It was arrived at mainly by trial and error but the goal was to have the popup cover just over half the screen (55vh)
    // the -8em is there to account for max height of the three rows of buttons of the nav bar which is fixed. So its half
    // the screen height minus that. mapClickPopupContainer just needs to contain all that and 55vh was pushing the nav
    // bar off the bottom slightly. 60vh seemed to work well though
    // In the future we may want to make the navbar collapsable to give the user more space if they want it.
    const popupContentContainerStyle = {
        ...classes.popupContentContainer,
        maxHeight: (isCellphone) ? 'calc(55vh - 8em)' : 'calc(100vh - 22em)',
    };

    // Get lat lon to display in popup header
    const [lon, lat] = toLonLat([props.mapClickCoords.x, props.mapClickCoords.y]);
    const mapClickTime = (props.mapClickTime) ? shortDateString(props.mapClickTime) + " " + getTimeZone() : "Present" ;

    return (
        <Paper elevation={3}
            sx={{
                ...sxStyles.mapClickPopupContainer,
                zIndex: props.zIndexVal
            }}
        >
            { (isCellphone) &&
                <>
                    <div style={popupContentContainerStyle}>
                        { popupContent }
                    </div>
                    <div style={{...classes.blueDivider, marginTop: '5px'}}></div>
                </>
            }
            <div style={classes.headerContainer}>
                <Typography variant="body2" sx={{height: '3.25em', paddingTop: '0.25em', fontSize: '0.8em', color:'#005194', fontWeight:'bold'}} align="center">{mapClickTime}<br />
                <span style={classes.pointIcon}></span>({lon.toFixed(2)}&#176; {(lon >= 0) ? "E" : "W"} , {lat.toFixed(2)}&#176; {(lat >= 0) ? "N" : "S"})
                </Typography>
                <IconButton size="small" ref={closeButtonHeight} onClick={() => {props.updateMapClickPopup(false); clearDotFeature();}} sx={sxStyles.popupCloseButton} >
                    <CloseIcon />
                </IconButton>
                <IconButton size="small" ref={closeButtonHeight} onClick={() => {props.updateMapClickPopup(false); clearDotFeature(); props.setLayerMenuOn(true)}} sx={sxStyles.popupLayersButton} >
                    <LayersIcon />
                </IconButton>
            </div>
            <div style={(isCellphone) ? classes.blueDivider : classes.divider}></div>
            <div style={classes.mapClickNavBar}>
                <Grid container justifyContent="center">
                    <Grid item>
                        { ("wwa" in props.mapClickInfo) &&
                            <Button
                                size="small"
                                sx={{
                                    ...sxStyles.popupNavButton,
                                    backgroundColor: (currentTab === "wwa") ? 'primary.main' : '#eee',
                                    color: (currentTab === "wwa") ? 'primary.contrastText' : "",
                                }}
                                onClick={() => {setCurrentTab("wwa")}}
                            >
                                <Typography variant="caption" sx={buttonTextStyle}>{TAB_LABELS.wwa}</Typography>
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                            <Button
                                size="small"
                                sx={{
                                    ...sxStyles.popupNavButton,
                                    backgroundColor: (currentTab === "point_forecast") ? 'primary.main' : '#eee',
                                    color: (currentTab === "point_forecast") ? 'primary.contrastText' : "",
                                }}
                                onClick={() => {setCurrentTab("point_forecast")}}
                            >
                                <Typography variant="caption" sx={buttonTextStyle}>{TAB_LABELS.point_forecast}</Typography>
                            </Button>
                    </Grid>
                        { ("zone_forecasts" in props.mapClickInfo) &&
                            <Grid item>
                                <Button
                                    size="small"
                                    sx={{
                                        ...sxStyles.popupNavButton,
                                        backgroundColor: (currentTab === "zone_forecasts") ? 'primary.main' : '#eee',
                                        color: (currentTab === "zone_forecasts") ? 'primary.contrastText' : "",
                                    }}
                                    onClick={() => {setCurrentTab("zone_forecasts")}}
                                >
                                    <Typography variant="caption" sx={buttonTextStyle}>{TAB_LABELS.zone_forecasts}</Typography>
                                </Button>
                            </Grid>
                        }
                        { ("ndfd" in props.mapClickInfo) &&
                            <Grid item>
                                <Button
                                    size="small"
                                    sx={{
                                        ...sxStyles.popupNavButton,
                                        backgroundColor: (currentTab === "ndfd") ? 'primary.main' : '#eee',
                                        color: (currentTab === "ndfd") ? 'primary.contrastText' : "",
                                    }}
                                    onClick={() => {setCurrentTab("ndfd")}}
                                >
                                    <Typography variant="caption" sx={buttonTextStyle}>{TAB_LABELS.ndfd}</Typography>
                                </Button>
                            </Grid>
                        }
                        {navBarContent}
                </Grid>
            </div>
            { (!isCellphone) &&
                <>
                    <div style={{...classes.divider, marginBottom: '5px'}}></div>
                    <div style={popupContentContainerStyle}>
                        { popupContent }
                    </div>
                </>
            }
            { (!isCellphone) &&
                <>
                    <div style={{...classes.divider, margin: '5px'}}></div>
                    <div>
                        <Typography sx={sxStyles.bottomCloseLink} onClick={() => {props.updateMapClickPopup(false); clearDotFeature();}} >Close</Typography>
                    </div>
                </>
            }
        </Paper>
    );
}

export default MapClickPopup;
