import TileLayer from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image';
import ImageWMSSource from 'ol/source/ImageWMS';
import TileWMSSource from 'ol/source/TileWMS';
import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import TileGrid from 'ol/tilegrid/TileGrid';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage.js';
import VectorLayer from 'ol/layer/Vector';
import { GeoJSON } from 'ol/format';
import { Cluster } from 'ol/source.js';

import {initENCSource, initNCDSSource} from './utilities/chart-services-config';
import { parseMETARClouds, parseMETARWeather, getWeatherText, parseObsTimestamp } from './utilities/utilities.js';

import {get as getProjection, transformExtent} from 'ol/proj';
import {getWidth, getCenter, getArea, getTopRight, getBottomRight, getTopLeft, getBottomLeft, boundingExtent} from 'ol/extent';

import { surfaceObsSymbology, staticSurfaceObsSymbology, COOPSSymbology } from './utilities/symbology-setter';
import { bbox } from 'ol/loadingstrategy.js';
import { defaults } from 'ol/control';

// Static layer of obs stations
import obsStaticGeoJSON from './assets/stations_w_buoys.json';

/**
* Configurations/Inputs for nowCOAST Map Viewer App
*/

// Pixel values to be passed to theme and used as breakpoints (lower bounds) (expects xs,sm,md,lg,xl)
export const BREAKPOINT_VALUES = {
  xs: 0, // Extra small devices (phones)
  sm: 600, // Small devices (tablets)
  md: 900, // Medium devices (desktops)
  lg: 1200, // Large devices (desktops)
  xl: 1536, // Extra large devices
};

// Starting Region for map viewer (previously was [-90, 38.958])
export const DEFAULT_MAP_POSITIONS = {
    xs: {
        center: [-90, 38.958], // tried -96.0, 33.0 and 3.25 zoom but got the marine zone boundary bug
        zoom: 4.25
    },
    sm: {
        center: [-88.0, 33.0],
        zoom: 4.25
    },
    md: {
        center: [-88.0, 33.0],
        zoom: 4.25
    },
    lg: {
        center: [-88.0, 33.0],
        zoom: 4.25
    },
    xl: {
        center: [-88.0, 33.0],
        zoom: 4.25
    },

};

// Starting Basemap (must be valid name of esri vector tile basemap)
export const DEFAULT_BASEMAP = "ArcGIS:StreetsRelief";

/**
* Base URLs (IP) used for each server that handles WMS requests
* WMS URLS are used as keys in WMSSources object,
* Invariant: One WMS_URL per capabilities request, one capabilities request per WMSSourceManager instance, one WMS_URL per WMSSourceManager instance
*/
const BASE_URL_GEOSERVER = '';

export const WMS_URL_WCOFS = BASE_URL_GEOSERVER + '/geoserver/wcofs/wms';
export const WMS_URL_CBOFS = BASE_URL_GEOSERVER + '/geoserver/cbofs/wms';
export const WMS_URL_GRTOFS = BASE_URL_GEOSERVER + '/geoserver/grtofs/wms';
export const WMS_URL_DBOFS = BASE_URL_GEOSERVER + '/geoserver/dbofs/wms';
export const WMS_URL_GOMOFS = BASE_URL_GEOSERVER + '/geoserver/gomofs/wms';
export const WMS_URL_TBOFS = BASE_URL_GEOSERVER + '/geoserver/tbofs/wms';
export const WMS_URL_SFBOFS = BASE_URL_GEOSERVER + '/geoserver/sfbofs/wms';
export const WMS_URL_LOOFS = BASE_URL_GEOSERVER + '/geoserver/loofs/wms';
export const WMS_URL_LSOFS = BASE_URL_GEOSERVER + '/geoserver/lsofs/wms';
export const WMS_URL_LMHOFS = BASE_URL_GEOSERVER + '/geoserver/lmhofs/wms';
export const WMS_URL_LEOFS = BASE_URL_GEOSERVER + '/geoserver/leofs/wms';
export const WMS_URL_NGOFS = BASE_URL_GEOSERVER + '/geoserver/ngofs/wms';
export const WMS_URL_NYOFS = BASE_URL_GEOSERVER + '/geoserver/nyofs/wms';
export const WMS_URL_NDFD_WAVE = BASE_URL_GEOSERVER + '/geoserver/ndfd_wave/wms';
export const WMS_URL_NDFD_SKY = BASE_URL_GEOSERVER + '/geoserver/ndfd_sky/wms';
export const WMS_URL_NDFD_TEMP = BASE_URL_GEOSERVER + '/geoserver/ndfd_temperature/wms';
export const WMS_URL_NDFD_PRECIP = BASE_URL_GEOSERVER + '/geoserver/ndfd_precipitation/wms';
export const WMS_URL_NDFD_MOISTURE = BASE_URL_GEOSERVER + '/geoserver/ndfd_moisture/wms';
export const WMS_URL_NDFD_WIND = BASE_URL_GEOSERVER + '/geoserver/ndfd_wind/wms';
export const WMS_URL_WWA = BASE_URL_GEOSERVER + '/geoserver/alerts/wms';
export const WMS_URL_MRMS = BASE_URL_GEOSERVER + '/geoserver/weather_radar/wms';
export const WMS_URL_TROPICAL_WX = BASE_URL_GEOSERVER + '/geoserver/tropical_cyclones/wms';
export const WMS_URL_TROPICAL_SS = BASE_URL_GEOSERVER + '/geoserver/tropical_storm_surge/wms';
export const WMS_URL_NBS = BASE_URL_GEOSERVER + '/geoserver/bluetopo/wms';
export const WMS_URL_STOFS = BASE_URL_GEOSERVER + '/geoserver/stofs3d/wms';
export const WMS_URL_SATELLITE = BASE_URL_GEOSERVER + '/geoserver/satellite/wms';
export const WMS_URL_SST = BASE_URL_GEOSERVER + '/geoserver/sea_surface_temperature/wms';
export const WMS_URL_S100 = BASE_URL_GEOSERVER + '/geoserver/s100/wms';
export const WMS_URL_PATHOGEN = BASE_URL_GEOSERVER + '/geoserver/marine_pathogen/wms';
export const WMS_URL_BOUNDARIES = BASE_URL_GEOSERVER + '/geoserver/boundaries/wms';
export const WMS_URL_LTNG = BASE_URL_GEOSERVER + '/geoserver/lightning_detection/wms';
export const WMS_URL_SPC = 'https://mapservices.weather.noaa.gov/vector/services/outlooks/SPC_wx_outlks/MapServer/WMSServer';
export const WMS_URL_NWS = 'https://mapservices.weather.noaa.gov/static/services/nws_reference_maps/nws_reference_map/MapServer/WMSServer';
export const WMS_URL_BEACH = 'https://mapservices.weather.noaa.gov/vector/services/outlooks/marine_beachforecast_summary/MapServer/WMSServer';
export const WFS_URL_SURFACEOBS = 'https://mapservices.weather.noaa.gov/vector/services/obs/surface_obs/MapServer/WFSServer';
export const WFS_URL_COOPS = 'https://mapservices.weather.noaa.gov/static/services/NOS_Observations/CO_OPS_Stations/MapServer/WFSServer';
export const WFS_URL_SURFACEOBS_FEATURE = WFS_URL_SURFACEOBS + '?version=2.0.0&request=GetFeature&service=WFS&typename=' +
    'surface_obs:Station_ID__Air_Temperature__deg_F___Dew_Point_Temperature__deg_F___Wind_Gust__kt___Mean_Sea-Level_Pressure__mb___3-Hour_Pressure_' +
    'Change__mb___Visibility__mi___Sea_Surface_Temperature__deg_F___Significant_Wave_Height__ft__-_Scale_Level_6&outputFormat=GEOJSON&srsname=' +
    'EPSG:4326&propertyName=shape,stationname,temperature,visibility,dewpoint,sst,waveheight,preschange,sealevelpress,cloudcover,winddir,windspeed,' +
    'windgust,timeobs,tblname,rawdata';
export const WFS_URL_COOPS_FEATURE = WFS_URL_COOPS + '?version=2.0.0&request=GetFeature&service=WFS&typename=CO_OPS_Stations:Waterlevel_Active_Stations' +
    '&outputFormat=GEOJSON&srsname=EPSG:4326&propertyName=shape,id,name,state,affil,latitude,longitude,data,metaapi,dataapi';

// TEMPORARY: This object maps wms URLS to service titles.
// This is a temporary patch employed after a geoserver security upgrade re-named all our services
export const WMS_SERVICE_TITLES = {
    [WMS_URL_WCOFS] : "West Coast Operational Forecast System",
    [WMS_URL_CBOFS] : "Chesapeake Bay Operational Forecast System",
    [WMS_URL_GRTOFS] : "Global Real-Time Operational Forecast System",
    [WMS_URL_DBOFS] : "Delaware Bay Operational Forecast System",
    [WMS_URL_GOMOFS] : "Gulf of Maine Operational Forecast System",
    [WMS_URL_TBOFS] : "Tampa Bay Operational Forecast System",
    [WMS_URL_SFBOFS] : "San Francisco Bay Operational Forecast System",
    [WMS_URL_LOOFS] : "Lake Ontario Operational Forecast System",
    [WMS_URL_LSOFS] : "Lake Superior Operational Forecast System",
    [WMS_URL_LMHOFS] : "Lake Michigan & Lake Huron Operational Forecast System",
    [WMS_URL_LEOFS] : "Lake Erie Operational Forecast System",
    [WMS_URL_NGOFS] : "Northern Gulf of Mexico Operational Forecast System",
    [WMS_URL_NYOFS] : "Port of New York & New Jersey Operational Forecast System",
    [WMS_URL_NDFD_WAVE] : "NDFD Significant Wave Height Forecasts",
    [WMS_URL_NDFD_SKY] : "NDFD Total Sky Cover Forecasts",
    [WMS_URL_NDFD_TEMP] : "NDFD Air Temperature Forecasts",
    [WMS_URL_NDFD_PRECIP] : "NDFD Precipitation Forecasts",
    [WMS_URL_NDFD_MOISTURE] : "NDFD Moisture Forecasts",
    [WMS_URL_NDFD_WIND] : "NDFD Wind Forecasts",
    [WMS_URL_WWA] : "Watches, Warnings, Advisories, Statements",
    [WMS_URL_MRMS] : "Weather Radar Base Reflectivity Mosaics",
    [WMS_URL_TROPICAL_WX] : "NHC & CPHC Tropical Cyclones",
    [WMS_URL_TROPICAL_SS] : "Tropical Storm Surge",
    [WMS_URL_NBS] : "BlueTopo",
    [WMS_URL_STOFS] : "Inland-Coastal Flooding Forecast Guidance",
    [WMS_URL_SATELLITE] : "Geostationary Satellite Imagery",
    [WMS_URL_S100] : "S100 Product Coverages",
    [WMS_URL_PATHOGEN] : "Chesapeake Bay Probability of Vibrio Vulnificus Occurrence",
    [WMS_URL_BOUNDARIES] : "DOD Military Working Areas",
    [WMS_URL_LTNG] : "Lightning Detection",
};

export const OL_ZINDEXES = {
    map_click_dot_layer: "999",
    s100_highlight_layer: "970",
    federal_boundaries_highlight_layer: "965",
    zone_forecasts_highlight_layer: "960",
    tropical_cyclones_highlight_layer: "955",
    wwa_highlight_layer: "950",
    bluetopo_highlight_layer: "945",
    convective_highlight_layer: "940",
    custom_layers: "500", // Custom layers may use 500-510 if there were 10 custom layers so leave a buffer up to max custom layers we want to support
    surface_obs: "151",
    co_ops_stations: "150",
    surface_obs_static: "149",
    federal_agency_boundaries: "135",
    tropical_cyclones: "130",
    ltng_den: "128",
    mrms_qpe: "126",
    mrms: "125",
    satellite: "123",
    beach_zone_forecasts: "122",
    zone_forecasts: "121",
    ndfd: "120",
    tropical_ss: "118",
    wwa: "116",
    convective_outlooks_significant: "115",
    convective_outlooks: "114",
    pathogen: "113",
    sst: "112",
    stofs: "111",
    s111: "110",
    nbs: "15",
    hill: "14",
    s100: "25",
    nautical_charts: "20",
    basemaps: "10",
};

// Define default sorting order for active layers
// Originally created to enforce arbitrary orders but we've since decided to sort
// layers alphabetically, but still are using this to do it
export const LAYER_MENU_ITEM_DISPLAY_ORDER = [
    "wwa", //active alerts
    "nbs", //bluetopo
    "convective_outlooks", //convective outlooks
    "federal_agency_boundaries", //federal boundaries
    "ndfd", //gridded forecasts
    "stofs", //inland coastal flooding
    "ltng_den", //lightning strike density
    "pathogen", //marine pathogen
    "nautical_charts",
    "mrms_qpe", //precipitation amounts
    "s100", //s100 coverages
    "sst", //sea surface temperature
    "surface_obs", //surface observations
    "s111", //surface water currents
    "tropical_cyclones", //tropical cyclones
    "mrms", //weather radar
    "satellite", //weather satellite
    "zone_forecasts", //zone forecasts
];

// Define representation of categories to be used in layer menu
// All layers must choose one of these category values
export const OCEAN_CATEGORY = "ocean";
export const FUTURE_CATEGORY = "future";
export const HAZARD_CATEGORY = "hazard";
export const PRESENT_CATEGORY = "present";
export const GUIDANCE_CATEGORY = "guidance";
export const OVERLAYS_CATEGORY = "overlays";
export const ANALYSIS_CATEGORY = "analysis";
export const OUTLOOKS_CATEGORY = "outlooks";
export const NO_CATEGORY = "";

// Generate resolutions and matrixIds arrays for WMTS
const projection = getProjection('EPSG:3857');
const projectionExtent = projection.getExtent();

const size = getWidth(projectionExtent) / 256;
const resolutions = new Array(31); //31 zoom levels (available in caps)
const matrixIds = new Array(31);
const matrixSet = 'EPSG:3857' // a name. also in caps
for (let z = 0; z < 31; ++z) {
  resolutions[z] = size / Math.pow(2, z);
  matrixIds[z] = matrixSet + ":" + z;
}

// Generate a custom tileGrid parameter for 512x512 Tiled WMS requests
const projExtent = getProjection('EPSG:3857').getExtent();
const startResolution = getWidth(projExtent) / 256;
const wms_resolutions = new Array(31);
for (let i = 0, ii = wms_resolutions.length; i < ii; ++i) {
  wms_resolutions[i] = startResolution / Math.pow(2, i);
}
const tileGrid = new TileGrid({
        extent: projExtent,
        resolutions: wms_resolutions,
        tileSize: [512, 512]
    });


// Function for determining if we are currently in hurricane season (today's date is between May 1 and Dec 31)
// Returns true if we are in hurricane season, else false
const inHurricaneSeason = () => {
    const today = new Date();
    const curYear = today.getFullYear();
    const seasonEnd = new Date(curYear, 11, 31); // Dec 31
    const seasonStart = new Date(curYear, 4, 1); // May 1
    return (today >= seasonStart && today <= seasonEnd);
}

/**
*   Definition of all layers in viewer
*   Format:
*   {
*       <Product Name (formerly called Layer Group)> : { // there will be one menu item with a toggle switch for each one of these
*           initialState: <true/false - does the layer start on or off>,
*           capUrl:  <url for capability requests, null if layer doesnt need capabilities>,
*           capEvents: <['eventName', ...] - list of events registered with cap handler for this layer>,
*           capRequestInterval: <frequency of capability requests in milliseconds>,
*           snapThreshold: <time (milliseconds). If the difference between this selected time and closest datapoint exceeds this values, the data will not be displayed
*           animated: <true/false - should the layer be animated (must have valid capUrl)>,
*           layers: {
*               <Open Layers Layer Name> : {
*                   defaultSource: <name of source that the layer will start with (corresponds to key in source list for this layer)>,
*                   layerObj: <instantiation of desired Open Layers layer object>,
*                   zIndex: <string containing default zIndex number for this layers stack position within Open Layers Map>
*                   sources: {
*                       <Open Layers Source Name>: {
*                           sourceObj: <instantiation of desired Open Layers Source>
*                       }, ...
*                   }
*               }, ...
*           }
*       }, ...
*   }
*
* NOTE:
*   In the OpenLayers Source Objects' instantiation the LAYERS parameter can not be a duplicate of any other LAYERS param
*   in the config, even if they are in separate products. For example:
*   params: {
*       'LAYERS': 'cbofs_sfc_currents', <-- Can't have duplicates of this value anywhere
*       'FORMAT': 'image/png8'
*       'TRANSPARENT': 'true'
*   },
*
*   Also that value cannot be shorter than 3 chars if it is to be a time-enabled layer
*   This is due to behavior in the <TimeControl> component. If we ever want to support duplicates or animate
*   layers with length-2 names, we must refactor <TimeControl>
*/

export const LAYERS = {
    s111: { //  product name used for tracking toggle state of the product (and usually the product's layers as well but all layers can be controlled individually)
        initialState: false, // does the product's toggle switch start on or off (true/false)
        capUrls: [
            WMS_URL_WCOFS,
            WMS_URL_CBOFS,
            WMS_URL_GRTOFS,
            WMS_URL_DBOFS,
            WMS_URL_GOMOFS,
            WMS_URL_TBOFS,
            WMS_URL_SFBOFS,
            WMS_URL_LOOFS,
            WMS_URL_LSOFS,
            WMS_URL_LMHOFS,
            WMS_URL_LEOFS,
            WMS_URL_NGOFS,
            WMS_URL_NYOFS,
        ], // urls for capability requests, null if layer doesnt need capabilities
        capEvents: ['capabilitiesUpdated', 'stylesUpdated', 'infoUpdated'],
        capRequestInterval: 20 * 60 * 1000, //20 min - frequency of capability requests
        snapThreshold: 12 * 60 * 60 * 1000, // 12 hours - dimension controller will not snap to values farther than this distance from selected time
        opacity: 100,
        animated: true, // should the layer be animated (must have valid capUrl)
        styleLayerNames: ["cbofs_sfc_currents"], // These names specify the layer names in capabilities where style info needs to be parsed product (Not currently used for s111 parse)
        keywords: ["ofs", "s111", "surface water currents"], // Only lowercase
        categories: [GUIDANCE_CATEGORY],
        layers: { // OL layers
            cbofs_sfc_currents: { // This layer key name is arbitrary (source names are NOT, this instance just happens to match the source name)
                defaultSource: "cbofs_sfc_currents", // source that the layer will start with
                initialState: false, // initial on/off state of this ol layer (a product that is toggled off will override a layer's on/off value)
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: { // each item corresponds to an OL source/geoserver layer (these keys must match value used in LAYERS param within OL source obj)
                    cbofs_sfc_currents: { // SOURCE, "LAYERS", parameter
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_CBOFS,
                            params: {
                                'LAYERS': 'cbofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                }
            },
            dbofs_sfc_currents: {
                defaultSource: "dbofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    dbofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_DBOFS,
                            params: {
                                'LAYERS': 'dbofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            gomofs_sfc_currents: {
                defaultSource: "gomofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    gomofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_GOMOFS,
                            params: {
                                'LAYERS': 'gomofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            ngofs_sfc_currents: {
                defaultSource: "ngofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    ngofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NGOFS,
                            params: {
                                'LAYERS': 'ngofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            leofs_sfc_currents: {
                defaultSource: "leofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    leofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_LEOFS,
                            params: {
                                'LAYERS': 'leofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            lmhofs_sfc_currents: {
                defaultSource: "lmhofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    lmhofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_LMHOFS,
                            params: {
                                'LAYERS': 'lmhofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            loofs_sfc_currents: {
                defaultSource: "loofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    loofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_LOOFS,
                            params: {
                                'LAYERS': 'loofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            lsofs_sfc_currents: {
                defaultSource: "lsofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    lsofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_LSOFS,
                            params: {
                                'LAYERS': 'lsofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            nyofs_sfc_currents: {
                defaultSource: "nyofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    nyofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NYOFS,
                            params: {
                                'LAYERS': 'nyofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            sfbofs_sfc_currents: {
                defaultSource: "sfbofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    sfbofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SFBOFS,
                            params: {
                                'LAYERS': 'sfbofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            tbofs_sfc_currents: {
                defaultSource: "tbofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    tbofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_TBOFS,
                            params: {
                                'LAYERS': 'tbofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            rtofs_east_sfc_currents: {
                defaultSource: "rtofs_east_sfc_currents",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    rtofs_east_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_GRTOFS,
                            params: {
                                'LAYERS': 'rtofs_east_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            rtofs_west_sfc_currents: {
                defaultSource: "rtofs_west_sfc_currents",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    rtofs_west_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_GRTOFS,
                            params: {
                                'LAYERS': 'rtofs_west_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
            wcofs_sfc_currents: {
                defaultSource: "wcofs_sfc_currents",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s111,
                sources: {
                    wcofs_sfc_currents: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_WCOFS,
                            params: {
                                'LAYERS': 'wcofs_sfc_currents',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
        }
    },
    nbs: {
        initialState: false,
        capUrls: [WMS_URL_NBS],
        capEvents: ['stylesUpdated', 'infoUpdated'],
        capRequestInterval: 60 * 60 * 1000, // Using long duration since data is not time enabled and we only need style info
        snapThreshold: Infinity,
        opacity: 75,
        animated: false,
        styleLayerNames: ["bathymetry"],
        keywords: ["bathymetry", "blue topo", "nbs", "national bathymetric survey"],
        categories: [OCEAN_CATEGORY],
        layers: {
            hillshade: { // TiledWMS Layer - do not use with WMTS layers enabled
                defaultSource: "hillshade",
                initialState: true,
                layerObj: new TileLayer(),
                zIndex: OL_ZINDEXES.hill,
                sources: {
                    "hillshade": {
                        sourceObj: new TileWMSSource({
                            url: WMS_URL_NBS,
                            tileGrid: tileGrid,
                            serverType: 'geoserver',
                            params: {
                                'LAYERS': 'hillshade',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': true,
                                'TILED': true,
                            },
                           })
                    },
                }
            },
            bathymetry: { // TiledWMS Layer - do not use with WMTS layers enabled
                defaultSource: "bathymetry",
                initialState: true,
                layerObj: new TileLayer(),
                zIndex: OL_ZINDEXES.nbs,
                sources: {
                    "bathymetry": {
                        sourceObj: new TileWMSSource({
                            url: WMS_URL_NBS,
                            tileGrid: tileGrid,
                            serverType: 'geoserver',
                            params: {
                                'LAYERS': 'bathymetry',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': true,
                                'STYLES': ['nbs_elevation'],
                                'TILED': true,
                            },
                        })
                    },
                }
            },
            bluetopo_tile_scheme: { // Always enabled regardless of wms/wmts
                defaultSource: "bluetopo_tile_scheme",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.nbs,
                sources: {
                    "bluetopo_tile_scheme": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NBS,
                            params: {
                                'LAYERS': 'bluetopo_tile_scheme',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': true,
                            },
                        })
                    },
                }
            },
//            hillshade: { // WMTS layer
//                defaultSource: "bluetopo:hillshade",
//                initialState: true,
//                layerObj: new TileLayer(),
//                zIndex: OL_ZINDEXES.hill,
//                sources: {
//                    "bluetopo:hillshade": {
//                        sourceObj: new WMTS({ // no params
//                            url: BASE_URL_GEOSERVER + '/geoserver/gwc/service/wmts?REQUEST=GetCapabilities', // change this. do we need a wmts cap handler? probly
//                            matrixSet: matrixSet, //
//                            format: 'image/png8',
//                            projection: projection, //caps
//                            tilePixelRatio: 2, // open layers setting - 2 means 512x512
//                            tileGrid: new WMTSTileGrid({
//                              origin: getTopLeft(projectionExtent),
//                              resolutions: resolutions,
//                              matrixIds: matrixIds //
//                            }),
//                            style: ['nbs_hillshade'],
//                            //strategy: bboxStrategy, //strategy for loading based on extent and resolution (there are other strategies, see OL docs) https://openlayers.org/en/latest/apidoc/module-ol_loadingstrategy.html
//                            serverType: "geoserver",
//                            layer: 'bluetopo:hillshade',
//                            hidpi: true,
//                        })
//                    },
//                }
//            },
//            bathymetry: { // WMTS Layer
//                defaultSource: "bluetopo:bathymetry",
//                initialState: true,
//                layerObj: new TileLayer(),
//                zIndex: OL_ZINDEXES.nbs,
//                sources: {
//                    "bluetopo:bathymetry": {
//                        sourceObj: new WMTS({ // no params
//                            url: BASE_URL_GEOSERVER + '/geoserver/gwc/service/wmts?REQUEST=GetCapabilities',
//                            matrixSet: matrixSet,
//                            format: 'image/png8',
//                            projection: projection,
//                            tilePixelRatio: 2,
//                            tileGrid: new WMTSTileGrid({
//                                origin: getTopLeft(projectionExtent),
//                                resolutions: resolutions,
//                                matrixIds: matrixIds
//                            }),
//                            style: ['nbs_elevation'],
//                            //strategy: bboxStrategy,
//                            serverType: "geoserver",
//                            layer: 'bluetopo:bathymetry',
//                            hidpi: true,
//                        })
//                    },
//                }
//            },
        }
    },
    s100: {
        initialState: false,
        capUrls: [WMS_URL_S100],
        capEvents: ['stylesUpdated', 'infoUpdated'],
        capRequestInterval: null,
        snapThreshold: null,
        opacity: 80,
        animated: false,
        styleLayerNames: ["s100_general_coverage", "s100_approach_coverage", "s100_harbor_coverage"],
        keywords: ["s100", "s111", "s102", "S-100", "S-111", "S-102", "coverages", "overlay", "boundaries", "s100 data", "precision marine navigation"],
        categories: [OVERLAYS_CATEGORY],
        layers: {
            s100_general_coverage: {
                defaultSource: "s100_general_coverage",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s100,
                sources: {
                    s100_general_coverage: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_S100,
                            params: {
                                'LAYERS': 's100_general_coverage',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                }
            },
            s100_approach_coverage: {
                defaultSource: "s100_approach_coverage",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s100,
                sources: {
                    s100_approach_coverage: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_S100,
                            params: {
                                'LAYERS': 's100_approach_coverage',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['s100_approach_coverage'],
                            },
                            ratio: 1
                        })
                    },
                }
            },
            s100_harbor_coverage: {
                defaultSource: "s100_harbor_coverage",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.s100,
                sources: {
                    s100_harbor_coverage: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_S100,
                            params: {
                                'LAYERS': 's100_harbor_coverage',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                }
            },
        }
    },
    ndfd: {
        initialState: false, // does the layer start on or off (true/false)
        capUrls: [
            WMS_URL_NDFD_WAVE,
            WMS_URL_NDFD_SKY,
            WMS_URL_NDFD_TEMP,
            WMS_URL_NDFD_PRECIP,
            WMS_URL_NDFD_MOISTURE,
            WMS_URL_NDFD_WIND,
        ], // urls for capability requests, null if layer doesnt need capabilities
        capEvents: ['capabilitiesUpdated', 'stylesUpdated', 'infoUpdated'],
        capRequestInterval: 20 * 60 * 1000, //20 min - frequency of capability requests
        snapThreshold: 12 * 60 * 60 * 1000,
        opacity: 65,
        animated: true, // should the layer be animated (must have valid capUrl)
        keywords: ["ndfd", "weather and wave forecasts", "weather", "wave", "temperature", "precipitation", "humidity", "snow", "wind"],
        categories: [FUTURE_CATEGORY],
        styleLayerNames: [
            "conus_significant_wave_height",
            "conus_apparent_temperature",
            "conus_dewpoint_temperature",
            "conus_maximum_temperature",
            "conus_minimum_temperature",
            "conus_6hr_precipitation_amount",
            "conus_12hr_precipitation_probability",
            "conus_relative_humidity",
            "conus_total_sky_cover",
            "conus_6hr_snow_amount",
            "conus_air_temperature",
            "conus_wind_velocity",
            "conus_wind_gust",
            "conus_wind_speed"
        ], // These names specify the layer names in capabilities where style info needs to be parsed
        layers: { //OPEN LAYERS LAYER(s)
            ndfd: {
                defaultSource: "significant_wave_height", // source that the layer will start with
                initialState: true, // initialState value at product level overrides this. Keep this true to maintain regular behavior of ndfd
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.ndfd,
                sources: { // each item corresponds to an OL source
                    "significant_wave_height": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_WAVE,
                            params: {
                                'LAYERS': 'significant_wave_height',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "apparent_temperature": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_TEMP,
                            params: {
                                'LAYERS': 'apparent_temperature',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "dewpoint_temperature": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_MOISTURE,
                            params: {
                                'LAYERS': 'dewpoint_temperature',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "maximum_temperature": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_TEMP,
                            params: {
                                'LAYERS': 'maximum_temperature',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "minimum_temperature": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_TEMP,
                            params: {
                                'LAYERS': 'minimum_temperature',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "6hr_precipitation_amount": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_PRECIP,
                            params: {
                                'LAYERS': '6hr_precipitation_amount',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "12hr_precipitation_probability": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_PRECIP,
                            params: {
                                'LAYERS': '12hr_precipitation_probability',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "relative_humidity": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_MOISTURE,
                            params: {
                                'LAYERS': 'relative_humidity',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "total_sky_cover": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_SKY,
                            params: {
                                'LAYERS': 'total_sky_cover',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "6hr_snow_amount": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_PRECIP,
                            params: {
                                'LAYERS': '6hr_snow_amount',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "air_temperature": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_TEMP,
                            params: {
                                'LAYERS': 'air_temperature',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "wind_velocity": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_WIND,
                            params: {
                                'LAYERS': 'wind_velocity',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "wind_gust": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_WIND,
                            params: {
                                'LAYERS': 'wind_gust',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                    "wind_speed": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NDFD_WIND,
                            params: {
                                'LAYERS': 'wind_speed',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                }
            },
        }
    },
    mrms: {
        //Service Refactoring Notes::
        // Phase 1 (adapting original config)
        //      All that is needed is a new WMS_URL_MRMS value as well as new name values
        //      that match the new names in capabilities. in this case "mrms_basereflect" needed to be
        //      updated everywhere that it appears (defaultSource, sources key, LAYERS param in source)
        //      This is because this design hard codes strings that are expected to be found in capabilities
        //      In this scheme, a config cannot be written without complete knowledge of the relevant parts
        //      of capabilities
        initialState: true, // does the layer start on or off (true/false)
        capUrls: [WMS_URL_MRMS], // url for capability requests, null if layer doesnt need capabilities
        capEvents: ['capabilitiesUpdated', 'stylesUpdated', 'infoUpdated'],
        capRequestInterval: 4 * 60 * 1000, // 4 min - frequency of capability requests
        snapThreshold: 60 * 60 * 1000, // 1 hour
        opacity: 45,
        animated: true, // should the layer be animated (must have valid capUrl)
        styleLayerNames: [''], // currently unused by parse
        keywords: ["mrms", "wxradar", "radar", "weather"], // Should be replaced by keywords from caps parse but this is not supported yet
        categories: [PRESENT_CATEGORY], // Categories within layer menu where this product can be found
        layers: { //OPEN LAYERS LAYER(s)
            base_reflectivity_mosaic: {
                defaultSource: "base_reflectivity_mosaic", // source that the layer will start with
                initialState: true, // Must be true if not using olLayerState for this product
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.mrms,
                sources: { // each item corresponds to an OL source
                    "base_reflectivity_mosaic": { // SOURCE, "LAYERS", parameter -  this name will ultimately be used to pull time info from the dimension state object. THAT is why it must match the layer parameter used within
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_MRMS,
                            params: {
                                'LAYERS': 'base_reflectivity_mosaic',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                },
            },
        },
    },
    mrms_qpe: {
        initialState: false,
        capUrls: ['https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer'],
        capEvents: ['stylesUpdated'],
        capRequestInterval: null,
        snapThreshold: null,
        opacity: 65,
        animated: false,
        styleLayerNames: ['mrms_qpe:rft_1hr', 'mrms_qpe:rft_3hr', 'mrms_qpe:rft_6hr', 'mrms_qpe:rft_12hr', 'mrms_qpe:rft_24hr', 'mrms_qpe:rft_48hr', 'mrms_qpe:rft_72hr'],
        keywords: ["precipitation", "qpe", "quantitative", "analysis", "mrms", "quantitative precipitation estimates", "rainfall", "amount", "multi-sensor", "weather radar", "satellite", "surface observations", "very high-resolution", "radar only", "nmq", "mrms", "nssl", "q3"],
        categories: [ANALYSIS_CATEGORY],
        layers: {
            mrms_qpe: {
                defaultSource: "mrms_qpe",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.mrms_qpe,
                sources: {
                    mrms_qpe: {
                        sourceObj: new ImageWMSSource({
                            url: "https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer",
                            params: {
                                'LAYERS': 'mrms_qpe:rft_24hr',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                }
            },
        }
    },
    tropical_cyclones: {
        initialState: inHurricaneSeason(),
        capUrls: [WMS_URL_TROPICAL_WX, WMS_URL_TROPICAL_SS],
        capEvents: ['stylesUpdated', 'infoUpdated'],
        capRequestInterval: 60 * 60 * 1000,
        snapThreshold: Infinity,
        opacity: 75,
        animated: false,
        keywords: ["tropical cyclones", "hurricane", "storm", "nhc", "tropical storm surge", "storm surge", "nhc", "inundation", "tidalmask"],
        categories: [HAZARD_CATEGORY],
        styleLayerNames: [
            'tropical_cyclone_current_wind_extent',
            'tropical_cyclone_track_forecast',
            'tropical_cyclone_cone_of_uncertainty_forecast',
            'tropical_cyclone_intensity_forecast',
            'tropical_cyclone_watches_warnings',
            'tropical_cyclone_observed_locations',
            'tropical_cyclone_observed_wind_extent', // Not on by default so does not appear in list below in params layers
            'tropical_cyclone_observed_track',
            'inundation',
            'tidalmask',
        ],
        layers: {
            tropical_cyclones: {
                defaultSource: "tropical_cyclones",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.tropical_cyclones,
                sources: {
                    tropical_cyclones: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_TROPICAL_WX,
                            params: {
                                'LAYERS': [
                                    'tropical_cyclone_observed_track',
                                    'tropical_cyclone_observed_locations',
                                    'tropical_cyclone_current_wind_extent',
                                    'tropical_cyclone_cone_of_uncertainty_forecast',
                                    'tropical_cyclone_track_forecast',
                                    'tropical_cyclone_intensity_forecast',
                                    'tropical_cyclone_watches_warnings',
                                    ],
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                }
            },
            tropical_ss: {
                defaultSource: "inundation",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.tropical_ss,
                sources: {
                    inundation: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_TROPICAL_SS,
                            params: {
                                'LAYERS': 'inundation', // or tidalmask
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                }
            }
        }
    },
    wwa: {
        initialState: true,
        capUrls: [WMS_URL_WWA],
        capEvents: ['stylesUpdated', 'infoUpdated'],
        capRequestInterval: 60 * 60 * 1000, // (1 hour) wwa updates every 2 min, BUT we only care about legend info from capabilities, so creating a much longer interval than 2 min
        snapThreshold: Infinity,
        opacity: 65,
        animated: false,
        styleLayerNames: ["watches_warnings_advisories"], // capabilities will only parse the first value in this list for WWA styles
        keywords: ["warning", "watch", "advisory", "wwa", "hazard", "condition", "statement"],
        categories: [HAZARD_CATEGORY],
        layers: {
            wwa: {
                defaultSource: "watches_warnings_advisories",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.wwa,
                sources: {
                    watches_warnings_advisories: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_WWA,
                            params: {
                                'LAYERS': 'watches_warnings_advisories',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1
                        })
                    },
                }
            }
        }
    },
    stofs: {
        initialState: false,
        capUrls: [WMS_URL_STOFS],
        capEvents: ['capabilitiesUpdated', 'stylesUpdated', 'infoUpdated'],
        capRequestInterval: 20 * 60 * 1000,
        snapThreshold: Infinity,
        opacity: 75,
        animated: true,
        styleLayerNames: ["stofs3d_atlantic_disturbance"], // Not used, but NOTE: this is the name in caps, which still must be hardcoded to legend component (doesnt match sourceName found in other state objects, is that an issue?)
        keywords: ["stofs", "storm surge"],
        categories: [GUIDANCE_CATEGORY],
        layers: {
            stofs3d_atlc_water_disturbance: {
                defaultSource: "stofs3d_atlc_water_disturbance",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.stofs,
                sources: {
                    stofs3d_atlc_water_disturbance: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_STOFS,
                            params: {
                                'LAYERS': 'stofs3d_atlc_water_disturbance',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                }
            }
        }
    },
    satellite: {
        initialState: true,
        capUrls: [WMS_URL_SATELLITE],
        capEvents: ['capabilitiesUpdated', 'stylesUpdated', 'infoUpdated'],
        capRequestInterval: 5 * 60 * 1000, // 5 min - frequency of capability requests
        snapThreshold: 3 * 60 * 60 * 1000, //5 * 60 * 1000, // 5 min
        opacity: 70,
        animated: true,
        styleLayerNames: ["global_longwave_imagery_mosaic"],
        keywords: ["satellite", "goes", "visible", "shortwave", "longwave", "water vapor", "snow", "ice", "observations", "present", "gmgsi"],
        categories: [PRESENT_CATEGORY],
        layers: {
            satellite: {
                defaultSource: "goes_longwave_imagery",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.satellite,
                sources: {
                    "goes_longwave_imagery": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'goes_longwave_imagery',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    "goes_shortwave_imagery": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'goes_shortwave_imagery',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    "goes_water_vapor_imagery": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'goes_water_vapor_imagery',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    "goes_visible_imagery": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'goes_visible_imagery',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    "goes_snow_ice_imagery": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'goes_snow_ice_imagery',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    "global_longwave_imagery_mosaic": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'global_longwave_imagery_mosaic',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    "global_shortwave_imagery_mosaic": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'global_shortwave_imagery_mosaic',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    "global_water_vapor_imagery_mosaic": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'global_water_vapor_imagery_mosaic',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    "global_visible_imagery_mosaic": {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SATELLITE,
                            params: {
                                'LAYERS': 'global_visible_imagery_mosaic',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                    }
            }
        }
    },
    nautical_charts: {
        initialState: false,
        capUrls: null,
        capEvents: null,
        capRequestInterval: null,
        snapThreshold: null,
        opacity: 100,
        animated: false,
        styleLayerNames: [], // empty for layers that do not rely on capabilities
        keywords: ["enc", "ecdis", "chart", "navigation", "map", "electronic navigational charts", "ncds", "noaa chart display"],
        categories: [OCEAN_CATEGORY, OVERLAYS_CATEGORY],
        layers: {
            nautical_charts: {
                defaultSource: "ncds_source_ft",
                initialState: true, // initialState value at product level overrides this. Keep this true to maintain regular behavior
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.nautical_charts,
                sources: {
                    enc_source_ft: {
                        sourceObj: initENCSource(2)
                    },
                    ncds_source_ft: {
                        sourceObj: initNCDSSource(2)
                    },
                    enc_source_m: {
                        sourceObj: initENCSource(1)
                    },
                    ncds_source_m: {
                        sourceObj: initNCDSSource(1)
                    },
                    enc_source_ftm: {
                        sourceObj: initENCSource(3)
                    },
                    ncds_source_ftm: {
                        sourceObj: initNCDSSource(3)
                    }
                }
            }
        }
    },
    surface_obs: {
        initialState: false,
        capUrls: [WFS_URL_SURFACEOBS, WFS_URL_COOPS],
        capEvents: null,
        capRequestInterval: 5 * 60 * 1000,
        snapThreshold: null,
        opacity: 100,
        animated: false,
        styleLayerNames: [],
        keywords: ['weather observations', 'marine weather', 'buoys', 'airports', 'in-situ', 'C-MAN stations', 'VOS', 'ASOS', 'AWOS', 'ships', 'NWS',
            'MADIS', 'station model', 'water levels', 'tide predictions', 'tide gauge', 'tide station', 'physical oceanography', 'PORTS', 'NWLON',
            'CO-OPS', 'NOS', 'NOAA'],
        categories: [PRESENT_CATEGORY],
        layers: {
            co_ops_stations: {
                defaultSource: "co_ops_stations",
                initialState: false,
                layerObj: new VectorImageLayer({
                    style: function (feature, resolution) {
                        const style = new COOPSSymbology(feature.get('features'), resolution);
                        return style.getStyle();
                    }
                }),
                zIndex: OL_ZINDEXES.co_ops_stations,
                sources: {
                    co_ops_stations: {
                        sourceObj: new Cluster({
                            source: new VectorSource({
                                url: WFS_URL_COOPS_FEATURE,
                                format: new GeoJSON()
                            }),
                            distance: 50,
                            minDistance: 10
                        })
                    }
                }
            },
            static_clusters: {
                defaultSource: "static_clusters",
                initialState: true,
                layerObj: new VectorImageLayer({
                    style: function (feature, resolution) {
                        const serviceSource = LAYERS['surface_obs'].layers['cached_stations'].sources['cached_stations'].sourceObj.source;
                        const style = new staticSurfaceObsSymbology(feature.get('features'), resolution);
                        return style.getStyle(serviceSource);
                    }
                }),
                zIndex: OL_ZINDEXES.surface_obs_static,
                sources: {
                    static_clusters: {
                        sourceObj: new Cluster({
                            source: new VectorSource({
                                loader: function() {
                                    const staticSource = LAYERS['surface_obs'].layers['static_clusters'].sources['static_clusters'].sourceObj.source;
                                    const features = new GeoJSON({dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'}).readFeatures(obsStaticGeoJSON);
                                    features.forEach((feature, index) => {
                                        let featureID = feature.getProperties()['lookupid'].toString();
                                        feature.setId(featureID);
                                        feature.setProperties({
                                            'stationname': feature.getProperties()['lookupid'].toString(),
                                            'location': feature.getProperties()['LOCATION'],
                                            'status': 'inactive'
                                        }, true);
                                    })
                                    staticSource.addFeatures(features);
                                },
                                format: new GeoJSON()
                            }),
                            distance: 50,
                            minDistance: 10
                        })
                    }
                }
            },
            cached_stations: {
                defaultSource: "cached_stations",
                initialState: true,
                layerObj: new VectorLayer({
                    style: function (feature, resolution) {
                        const style = new surfaceObsSymbology(feature.get('features'), resolution)
                        return style.getStyle()
                    }
                }),
                zIndex: OL_ZINDEXES.surface_obs,
                sources: {
                    cached_stations: {
                        sourceObj: new Cluster ({
                            source: new VectorSource({
                                format: new GeoJSON()
                            }),
                            distance: 90,
                            minDistance: 20
                        })
                    }
                }
            },
            station_obs_scale6: {
                defaultSource: "station_obs_scale6",
                initialState: true,
                layerObj: new VectorLayer({
                    style: function (feature, resolution) {
                        const style = new surfaceObsSymbology(feature.get('features'), resolution);
                        return style.getStyle()
                    }
                }),
                zIndex: OL_ZINDEXES.surface_obs,
                sources: {
                    station_obs_scale6: {
                        sourceObj: new Cluster ({
                            source: new VectorSource({
                                loader: function(extent, resolution, projection, success, failure) {
                                    const cachedSource = LAYERS['surface_obs'].layers['cached_stations'].sources['cached_stations'].sourceObj.source;
                                    const staticSource = LAYERS['surface_obs'].layers['static_clusters'].sources['static_clusters'].sourceObj.source;
                                    const extent4326 = transformExtent(extent, projection, 'EPSG:4326');
                                    const flippedExtent = [extent4326[1], extent4326[0], extent4326[3], extent4326[2]];
                                    getData(flippedExtent);
                                    // Recursive function that divides bbox requests into quadrants if extent area is too large
                                    // This allows features to load in smaller chunks
                                    function getData(extent) {
                                        if(getArea(extent) > 1000.0) {
                                            const center = getCenter(extent);
                                            const corners = [getTopLeft(extent), getTopRight(extent), getBottomRight(extent), getBottomLeft(extent)];
                                            for(const n in corners) {
                                                const quadrantExtent = boundingExtent([center, corners[n]]);
                                                getData(quadrantExtent);
                                            }
                                        } else {
                                            let url = WFS_URL_SURFACEOBS_FEATURE + '&bbox=' + extent.join(',') + ',EPSG:4326';
                                            requestStationData(url);
                                        }
                                    };
                                    // Request and load data only if response is valid
                                    async function requestStationData(url) {
                                        let response = await fetch(url).then((response) => {
                                            if(response.status >= 400 && response.status < 600) {
                                                return null;
                                            } else {
                                                return response;
                                            }
                                        }).catch((error) => {
                                            return null;
                                        });
                                        if(response) {
                                            if(response.status < 400 || response.status >= 600) {
                                                let responseData = await response.text();
                                                const features = new GeoJSON({dataProjection: 'EPSG:4326', featureProjection: projection}).readFeatures(responseData);
                                                const newFeatures = []
                                                // Loads new data if not expired and newer than cached station
                                                features.forEach((feature) => {
                                                    const cacheDuplicates = cachedSource.getFeaturesAtCoordinate(feature.getGeometry().getCoordinates());
                                                    const newTimestamp = parseObsTimestamp(feature.getProperties()['timeobs']);
                                                    const expireTime = new Date(new Date() - (2 * 60 * 60 * 1000));
                                                    if(newTimestamp > expireTime) {
                                                        if(cacheDuplicates.length) {
                                                            for(const duplicateFeature of cacheDuplicates) {
                                                                if(newTimestamp > parseObsTimestamp(duplicateFeature.getProperties()['timeobs'])) {
                                                                    cachedSource.removeFeature(duplicateFeature);
                                                                    newFeatures.push(formatFeature(feature));
                                                                }
                                                            }
                                                        } else {
                                                            newFeatures.push(formatFeature(feature));
                                                        }
                                                    }
                                                });
                                                cachedSource.addFeatures(newFeatures);
                                                success(features);
                                            }
                                        }
                                    };
                                    // Sets feature ID to be stationname and sets status to active
                                    function formatFeature(feature) {
                                        let formattedFeature = feature;
                                        const featureID = formattedFeature.getProperties()['stationname'].toString();
                                        formattedFeature.setId(featureID);
                                        formattedFeature.setProperties({'status': 'active'});
                                        const staticCopy = staticSource.getFeatureById(featureID);
                                        if(staticCopy) {
                                            const location = staticCopy.getProperties()['location'];
                                            formattedFeature.setProperties({'location': location});
                                        }
                                        const weather = parseMETARWeather(formattedFeature.getProperties()['rawdata']);
                                        const cloudCover = parseMETARClouds(formattedFeature.getProperties()['rawdata']);
                                        let weatherCodeString = "";
                                        if(weather.length > 0) {
                                            for(const code of weather) {
                                                weatherCodeString = weatherCodeString + " " + code;
                                            }
                                            const weatherText = getWeatherText(weather);
                                            formattedFeature.setProperties({'weather-text': weatherText});
                                        }
                                        formattedFeature.setProperties({'metar-weather': weatherCodeString});
                                        formattedFeature.setProperties({'metar-cloud': cloudCover});
                                        return formattedFeature;
                                    };
                                },
                                format: new GeoJSON(),
                                strategy: bbox
                            }),
                            distance: 50,
                            minDistance: 30
                        })
                    }
                }
            }
        }
    },
    federal_agency_boundaries: {
        initialState: false,
        capUrls: [WMS_URL_BOUNDARIES],
        capEvents: ['stylesUpdated', 'infoUpdated'],
        capRequestInterval: null,
        snapThreshold: null,
        opacity: 100,
        animated: false,
        styleLayerNames: ["military_boundaries"],
        keywords: ["federal agency boundaries", "boundaries", "boundary", "administrative", "borders", "overlay", "zone", "county warning areas", "river forecast center", "training areas", "airspace", "Navy", "Defense", "Marine Corps", "seaspace", "subseaspace", "OPAREA", "special use", "SUA", "range complex"],
        categories: [OVERLAYS_CATEGORY],
        layers: {
            cwa_boundaries: {
                defaultSource: "cwa_boundaries",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.federal_agency_boundaries,
                sources: {
                    cwa_boundaries: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NWS,
                            params: {
                                'LAYERS': '11',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1,
                        })
                    },
                }
            },
            river_forcast_centers: {
                defaultSource: "river_forcast_centers",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.federal_agency_boundaries,
                sources: {
                    river_forcast_centers: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NWS,
                            params: {
                                'LAYERS': '10',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1,
                        })
                    },
                }
            },
            military_boundaries: {
                defaultSource: "military_boundaries",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.federal_agency_boundaries,
                sources: {
                    military_boundaries: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_BOUNDARIES,
                            params: {
                                'LAYERS': 'military_boundaries',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['military_boundaries']
                            },
                            ratio: 1
                        })
                    },
                }
            },
        }
    },
    convective_outlooks: {
        initialState: false,
        capUrls: [WMS_URL_BOUNDARIES],
        capEvents: null,
        capRequestInterval: null,
        snapThreshold: null,
        opacity: 75,
        animated: false,
        styleLayerNames: [],
        keywords: ["Convective Outlooks", "Severe Thunderstorms", "Severe Storms", "Severe Weather", "Tornadoes", "Damaging Winds", "Large Hail", "Probability", "NWS", "Storm Prediction Center"],
        categories: [OUTLOOKS_CATEGORY],
        layers: {
            categorical_day1: {
                defaultSource: "categorical_day1",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    categorical_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '25',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            significant_tornado_day1: {
                defaultSource: "significant_tornado_day1",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks_significant,
                sources: {
                    significant_tornado_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '24',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_tornado_day1: {
                defaultSource: "probabilistic_tornado_day1",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_tornado_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '23',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            significant_hail_day1: {
                defaultSource: "significant_hail_day1",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks_significant,
                sources: {
                    significant_hail_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '22',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_hail_day1: {
                defaultSource: "probabilistic_hail_day1",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_hail_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '21',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    },
                }
            },
            significant_wind_day1: {
                defaultSource: "significant_wind_day1",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks_significant,
                sources: {
                    significant_wind_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '20',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_wind_day1: {
                defaultSource: "probabilistic_wind_day1",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_wind_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '19',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            categorical_day2: {
                defaultSource: "categorical_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    categorical_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '17',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            significant_tornado_day2: {
                defaultSource: "significant_tornado_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks_significant,
                sources: {
                    significant_tornado_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '16',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_tornado_day2: {
                defaultSource: "probabilistic_tornado_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_tornado_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '15',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            significant_hail_day2: {
                defaultSource: "significant_hail_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks_significant,
                sources: {
                    significant_hail_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '14',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_hail_day2: {
                defaultSource: "probabilistic_hail_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_hail_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '13',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            significant_wind_day2: {
                defaultSource: "significant_wind_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks_significant,
                sources: {
                    significant_wind_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '12',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_wind_day2: {
                defaultSource: "probabilistic_wind_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_wind_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '11',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            categorical_day3: {
                defaultSource: "categorical_day3",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    categorical_day3: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '9',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            significant_severe_day3: {
                defaultSource: "significant_severe_day3",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks_significant,
                sources: {
                    significant_severe_day3: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '8',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_severe_day3: {
                defaultSource: "probabilistic_severe_day3",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_severe_day3: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '7',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_severe_day4: {
                defaultSource: "probabilistic_severe_day4",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_severe_day4: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '5',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_severe_day5: {
                defaultSource: "probabilistic_severe_day5",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_severe_day5: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '4',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_severe_day6: {
                defaultSource: "probabilistic_severe_day6",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_severe_day6: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '3',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_severe_day7: {
                defaultSource: "probabilistic_severe_day7",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_severe_day7: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '2',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            probabilistic_severe_day8: {
                defaultSource: "probabilistic_severe_day8",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.convective_outlooks,
                sources: {
                    probabilistic_severe_day8: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SPC,
                            params: {
                                'LAYERS': '1',
                                'FORMAT': 'image/png8',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            }
        }
    },
    zone_forecasts: {
        initialState: true,
        capUrls: null,
        capEvents: null,
        capRequestInterval: null,
        snapThreshold: null,
        opacity: 100,
        animated: false,
        styleLayerNames: [], // empty for layers that do not rely on capabilities
        keywords: ["marine forecast", "coastal marine zone forecasts", "offshore zone forecasts", "zone", "high seas zone forecasts", "inland", "public weather zone forecasts", "fire", "fire weather zone forecasts", "beach hazards", "surf zone", "rip currents", "beach and surf zone forecasts", "rip currents and swim risk levels", "Great Lakes", "near shore zone forecast", "open lake zone forecast", "near shore", "open lake", "marine weather"],
        categories: [FUTURE_CATEGORY],
        layers: {
            beach_forecasts_day1: {
                defaultSource: "beach_forecasts_day1",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.beach_zone_forecasts,
                sources: {
                    beach_forecasts_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_BEACH,
                            params: {
                                'LAYERS': '4',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            beach_forecasts_day2: {
                defaultSource: "beach_forecasts_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.beach_zone_forecasts,
                sources: {
                    beach_forecasts_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_BEACH,
                            params: {
                                'LAYERS': '3',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            great_lakes_beach_forecasts_day1: {
                defaultSource: "great_lakes_beach_forecasts_day1",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.beach_zone_forecasts,
                sources: {
                    great_lakes_beach_forecasts_day1: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_BEACH,
                            params: {
                                'LAYERS': '2',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            great_lakes_beach_forecasts_day2: {
                defaultSource: "great_lakes_beach_forecasts_day2",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.beach_zone_forecasts,
                sources: {
                    great_lakes_beach_forecasts_day2: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_BEACH,
                            params: {
                                'LAYERS': '1',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1
                        })
                    }
                }
            },
            coastal_marine_zone_forecasts: {
                defaultSource: "coastal_marine_zone_forecasts",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.zone_forecasts,
                sources: {
                    coastal_marine_zone_forecasts: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NWS,
                            params: {
                                'LAYERS': '6',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1,
                        })
                    },
                }
            },
            offshore_zone_forecasts: {
                defaultSource: "offshore_zone_forecasts",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.zone_forecasts,
                sources: {
                    offshore_zone_forecasts: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NWS,
                            params: {
                                'LAYERS': '5',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1,
                        })
                    },
                }
            },
            high_seas_zone_forecasts: {
                defaultSource: "high_seas_zone_forecasts",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.zone_forecasts,
                sources: {
                    high_seas_zone_forecasts: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NWS,
                            params: {
                                'LAYERS': '4',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1,
                        })
                    },
                }
            },
            public_weather_zones: {
                defaultSource: "public_weather_zones",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.zone_forecasts,
                sources: {
                    public_weather_zones: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NWS,
                            params: {
                                'LAYERS': '2',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1,
                        })
                    },
                }
            },
            fire_weather_zones : {
                defaultSource: "fire_weather_zones",
                initialState: false,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.zone_forecasts,
                sources: {
                    fire_weather_zones: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_NWS,
                            params: {
                                'LAYERS': '1',
                                'FORMAT': 'image/png32',
                                'TRANSPARENT': 'true',
                                'STYLES': ['default']
                            },
                            ratio: 1,
                        })
                    },
                }
            }
        }
    },
    sst: {
        initialState: false,
        capUrls: [WMS_URL_SST],
        capEvents: ['capabilitiesUpdated', 'stylesUpdated', 'infoUpdated'],
        capRequestInterval: 20 * 60 * 1000,
        snapThreshold: Infinity, //24 * 60 * 60 * 1000,
        opacity: 100,
        animated: true,
        styleLayerNames: ["nasa_sport_sea_surface_temperature"], // Not used, but NOTE: this is the name in caps, which still must be hardcoded to legend component (doesnt match sourceName found in other state objects, is that an issue?)
        keywords: ["analysis", "sst", "sea surface temperature", "past", "oceanographic",],
        categories: [ANALYSIS_CATEGORY],
        layers: {
            global_sea_surface_temperature: {
                defaultSource: "global_sea_surface_temperature",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.sst,
                sources: {
                    global_sea_surface_temperature: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SST,
                            params: {
                                'LAYERS': 'global_sea_surface_temperature',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true'
                            },
                            ratio: 1,
                        })
                    },
                }
            },
            nasa_sport_sea_surface_temperature: {
                defaultSource: "nasa_sport_sea_surface_temperature",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.sst,
                sources: {
                    nasa_sport_sea_surface_temperature: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_SST,
                            params: {
                                'LAYERS': 'nasa_sport_sea_surface_temperature',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                }
            }
        }
    },
    ltng_den: {
        initialState: false,
        capUrls: [WMS_URL_LTNG],
        capEvents: ['capabilitiesUpdated', 'stylesUpdated', 'infoUpdated'],
        capRequestInterval: 20 * 60 * 1000,
        snapThreshold: 12 * 60 * 60 * 1000,
        opacity: 65,
        animated: true,
        styleLayerNames: ["lightning_density"],
        keywords: ["observations", "lightning density", "cloud-to-cloud strikes", "cloud-to-ground strikes", "NWS", "NCEP", "OPC"],
        categories: [PRESENT_CATEGORY],
        layers: {
            ldn_lightning_strike_density: {
                defaultSource: "ldn_lightning_strike_density",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.ltng_den,
                sources: {
                    ldn_lightning_strike_density: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_LTNG,
                            params: {
                                'LAYERS': 'ldn_lightning_strike_density',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                }
            }
        }
    },
    pathogen: {
        initialState: false,
        capUrls: [WMS_URL_PATHOGEN],
        capEvents: ['capabilitiesUpdated', 'stylesUpdated', 'infoUpdated'],
        capRequestInterval: 20 * 60 * 1000,
        snapThreshold: 12 * 60 * 60 * 1000,
        opacity: 100,
        animated: true,
        styleLayerNames: ["chesbay_vibrio_vulnificus"],
        keywords: ["marine pathogen", "guidance", "OFS", "NOS", "NCCOS", "ecological forecast"],
        categories: [GUIDANCE_CATEGORY],
        layers: {
            vibrio_cbofs: {
                defaultSource: "chesbay_vibrio_vulnificus",
                initialState: true,
                layerObj: new ImageLayer(),
                zIndex: OL_ZINDEXES.pathogen,
                sources: {
                    chesbay_vibrio_vulnificus: {
                        sourceObj: new ImageWMSSource({
                            url: WMS_URL_PATHOGEN,
                            params: {
                                'LAYERS': 'chesbay_vibrio_vulnificus',
                                'FORMAT': 'image/png',
                                'TRANSPARENT': 'true',
                            },
                            ratio: 1,
                        })
                    },
                }
            }
        }
    },
};

export const SATELLITE_INFO = {
    goes_longwave_imagery: {
        label: 'GOES Longwave (East/West)',
        layerName: 'goes_longwave_imagery',
    },
    global_longwave_imagery_mosaic: {
        label: 'Global Longwave Mosaic',
        layerName: 'global_longwave_imagery_mosaic',
    },
    goes_shortwave_imagery: {
        label: 'GOES Shortwave (East/West)',
        layerName: 'goes_shortwave_imagery',
    },
    global_shortwave_imagery_mosaic: {
        label: 'Global Shortwave Mosaic',
        layerName: 'global_shortwave_imagery_mosaic',
    },
    goes_visible_imagery: {
        label: 'GOES Visible (East/West)',
        layerName: 'goes_visible_imagery',
    },
    global_visible_imagery_mosaic: {
        label: 'Global Visible Mosaic',
        layerName: 'global_visible_imagery_mosaic',
    },
    goes_water_vapor_imagery: {
        label: 'GOES Water Vapor (East/West)',
        layerName: 'goes_water_vapor_imagery',
    },
    global_water_vapor_imagery_mosaic: {
        label: 'Global Water Vapor Mosaic',
        layerName: 'global_water_vapor_imagery_mosaic',
    },
    goes_snow_ice_imagery: {
        label: 'GOES Snow & Ice (East/West)',
        layerName: 'goes_snow_ice_imagery',
    },
};

export const OFS_SFC_CURRENTS_INFO = {
    cbofs_sfc_currents: {
        label: 'Chesapeake Bay',
        layerName: 'cbofs_sfc_currents',
    },
    dbofs_sfc_currents: {
        label: 'Delaware Bay',
        layerName: 'dbofs_sfc_currents',
    },
    gomofs_sfc_currents: {
        label: 'Gulf of Maine',
        layerName: 'gomofs_sfc_currents',
    },
    ngofs_sfc_currents: {
        label: 'Northern Gulf of Mexico',
        layerName: 'ngofs_sfc_currents',
    },
    leofs_sfc_currents: {
        label: 'Lake Erie',
        layerName: 'leofs_sfc_currents',
    },
    lmhofs_sfc_currents: {
        label: 'Lake Michigan & Huron',
        layerName: 'lmhofs_sfc_currents',
    },
    loofs_sfc_currents: {
        label: 'Lake Ontario',
        layerName: 'loofs_sfc_currents',
    },
    lsofs_sfc_currents: {
        label: 'Lake Superior',
        layerName: 'lsofs_sfc_currents',
    },
    nyofs_sfc_currents: {
        label: 'New York/New Jersey Harbor',
        layerName: 'nyofs_sfc_currents',
    },
    sfbofs_sfc_currents: {
        label: 'San Francisco Bay',
        layerName: 'sfbofs_sfc_currents',
    },
    tbofs_sfc_currents: {
        label: 'Tampa Bay',
        layerName: 'tbofs_sfc_currents',
    },
    wcofs_sfc_currents: {
        label: 'West Coast',
        layerName: 'wcofs_sfc_currents',
    },
    rtofs_east_sfc_currents: {
        label: 'US East',
        layerName: 'rtofs_east_sfc_currents',
    },
    rtofs_west_sfc_currents: {
        label: 'US West',
        layerName: 'rtofs_west_sfc_currents',
    }
};

export const NDFD_LAYER_INFO = {
    apparent_temperature: {
        label: 'Apparent Temperature',
        layerName: 'apparent_temperature',
        decimals: 1, // precision to the right of the decimal
        unit: '\xB0 F', // unit to be displayed with point data
    },
    dewpoint_temperature: {
        label: 'Dewpoint Temperature',
        layerName: 'dewpoint_temperature',
        decimals: 1,
        unit: '\xB0 F',
    },
    maximum_temperature: {
        label: 'Max Temperature',
        layerName: 'maximum_temperature',
        decimals: 1,
        unit: '\xB0 F',
    },
    minimum_temperature: {
        label: 'Min Temperature',
        layerName: 'minimum_temperature',
        decimals: 1,
        unit: '\xB0 F',
    },
    '6hr_precipitation_amount': {
        label: '6-Hr Precipitation Amount',
        layerName: '6hr_precipitation_amount',
        decimals: 2,
        unit: 'inches',
    },
    '12hr_precipitation_probability': {
        label: '12-Hr Precipitation Probability',
        layerName: '12hr_precipitation_probability',
        decimals: 0,
        unit: '%',
    },
    relative_humidity: {
        label: 'Relative Humidity',
        layerName: 'relative_humidity',
        decimals: 0,
        unit: '%',
    },
    total_sky_cover: {
        label: 'Total Sky Cover',
        layerName: 'total_sky_cover',
        decimals: 0,
        unit: '%',
    },
    '6hr_snow_amount': {
        label: '6-Hr Snow Amount',
        layerName: '6hr_snow_amount',
        decimals: 1,
        unit: 'inches',
    },
    air_temperature: {
        label: 'Air Temperature',
        layerName: 'air_temperature',
        decimals: 1,
        unit: '\xB0 F',
    },
    significant_wave_height: {
        label: 'Significant Wave Height',
        layerName: 'significant_wave_height',
        decimals: 1,
        unit: 'ft',
    },
    wind_velocity: {
        label: 'Wind Speed & Direction',
        layerName: 'wind_velocity',
        decimals: 0, // also 0 for direction
        unit: 'knots', // also degrees for direction
    },
    wind_gust: {
        label: 'Wind Gust',
        layerName: 'wind_gust',
        decimals: 0,
        unit: 'knots',
    },
    wind_speed: {
        label: 'Wind Speed',
        layerName: 'wind_speed',
        decimals: 0,
        unit: 'knots',
    }
};

export const WWA_COLORS = {
    "test": {
        "name": "Test",
        "color": "#F0FFFF"
    },
    "administrativeMessage": {
        "name": "Administrative Message",
        "color": "#C0C0C0"
    },
    "shortTermForecast": {
        "name": "Short Term Forecast",
        "color": "#98FB98"
    },
    "hydrologicOutlook": {
        "name": "Hydrologic Outlook",
        "color": "#90EE90"
    },
    "hazardousWeatherOutlook": {
        "name": "Hazardous Weather Outlook",
        "color": "#EEE8AA"
    },
    "airStagnationAdvisory": {
        "name": "Air Stagnation Advisory",
        "color": "#808080"
    },
    "airQualityAlert": {
        "name": "Air Quality Alert",
        "color": "#808080"
    },
    "marineWeatherStatement": {
        "name": "Marine Weather Statement",
        "color": "#FFDAB9"
    },
    "specialWeatherStatement": {
        "name": "Special Weather Statement",
        "color": "#FFE4B5"
    },
    "lakeshoreFloodStatement": {
        "name": "Lakeshore Flood Statement",
        "color": "#6B8E23"
    },
    "coastalFloodStatement": {
        "name": "Coastal Flood Statement",
        "color": "#6B8E23"
    },
    "911TelephoneOutage": {
        "name": "911 Telephone Outage",
        "color": "#C0C0C0"
    },
    "extremeFireDanger": {
        "name": "Extreme Fire Danger",
        "color": "#E9967A"
    },
    "fireWeatherWatch": {
        "name": "Fire Weather Watch",
        "color": "#FFDEAD"
    },
    "freezeWatch": {
        "name": "Freeze Watch",
        "color": "#00FFFF"
    },
    "hardFreezeWatch": {
        "name": "Hard Freeze Watch",
        "color": "#41690"
    },
    "lakeEffectSnowWatch": {
        "name": "Lake Effect Snow Watch",
        "color": "#87CEFA"
    },
    "windChillWatch": {
        "name": "Wind Chill Watch",
        "color": "#5F9EA0"
    },
    "extremeColdWatch": {
        "name": "Extreme Cold Watch",
        "color": "#5F9EA0"
    },
    "excessiveHeatWatch": {
        "name": "Excessive Heat Watch",
        "color": "#800000"
    },
    "highWindWatch": {
        "name": "High Wind Watch",
        "color": "#B8860B"
    },
    "floodWatch": {
        "name": "Flood Watch",
        "color": "#2E8B57"
    },
    "lakeshoreFloodWatch": {
        "name": "Lakeshore Flood Watch",
        "color": "#66CDAA"
    },
    "coastalFloodWatch": {
        "name": "Coastal Flood Watch",
        "color": "#66CDAA"
    },
    "heavyFreezingSprayWatch": {
        "name": "Heavy Freezing Spray Watch",
        "color": "#BC8F8F"
    },
    "hazardousSeasWatch": {
        "name": "Hazardous Seas Watch",
        "color": "#483D8B"
    },
    "winterStormWatch": {
        "name": "Winter Storm Watch",
        "color": "#4682B4"
    },
    "galeWatch": {
        "name": "Gale Watch",
        "color": "#FFC0CB"
    },
    "beachHazardsStatement": {
        "name": "Beach Hazards Statement",
        "color": "#40E0D0"
    },
    "ripCurrentStatement": {
        "name": "Rip Current Statement",
        "color": "#40E0D0"
    },
    "blizzardWatch": {
        "name": "Blizzard Watch",
        "color": "#ADFF2F"
    },
    "avalancheWatch": {
        "name": "Avalanche Watch",
        "color": "#F4A460"
    },
    "childAbductionEmergency": {
        "name": "Child Abduction Emergency",
        "color": "#FFFFFF"
    },
    "localAreaEmergency": {
        "name": "Local Area Emergency",
        "color": "#C0C0C0"
    },
    "lowWaterAdvisory": {
        "name": "Low Water Advisory",
        "color": "#A52A2A"
    },
    "freezingSprayAdvisory": {
        "name": "Freezing Spray Advisory",
        "color": "#00BFFF"
    },
    "freezingFogAdvisory": {
        "name": "Freezing Fog Advisory",
        "color": "#8080"
    },
    "ashfallAdvisory": {
        "name": "Ashfall Advisory",
        "color": "#696969"
    },
    "frostAdvisory": {
        "name": "Frost Advisory",
        "color": "#6495ED"
    },
    "windAdvisory": {
        "name": "Wind Advisory",
        "color": "#D2B48C"
    },
    "lakeWindAdvisory": {
        "name": "Lake Wind Advisory",
        "color": "#D2B48C"
    },
    "blowingDustAdvisory": {
        "name": "Blowing Dust Advisory",
        "color": "#BDB76B"
    },
    "dustAdvisory": {
        "name": "Dust Advisory",
        "color": "#BDB76B"
    },
    "hazardousSeasWarning": {
        "name": "Hazardous Seas Warning",
        "color": "#D8BFD8"
    },
    "briskWindAdvisory": {
        "name": "Brisk Wind Advisory",
        "color": "#D8BFD8"
    },
    "smallCraftAdvisory": {
        "name": "Small Craft Advisory",
        "color": "#D8BFD8"
    },
    "smallCraftAdvisoryforWinds": {
        "name": "Small Craft Advisory for Winds",
        "color": "#D8BFD8"
    },
    "smallCraftAdvisoryforRoughBar": {
        "name": "Small Craft Advisory for Rough Bar",
        "color": "#D8BFD8"
    },
    "smallCraftAdvisoryForHazardousSeas": {
        "name": "Small Craft Advisory For Hazardous Seas",
        "color": "#D8BFD8"
    },
    "denseSmokeAdvisory": {
        "name": "Dense Smoke Advisory",
        "color": "#F0E68C"
    },
    "denseFogAdvisory": {
        "name": "Dense Fog Advisory",
        "color": "#708090"
    },
    "heavyFreezingSprayWarning": {
        "name": "Heavy Freezing Spray Warning",
        "color": "#00BFFF"
    },
    "highSurfAdvisory": {
        "name": "High Surf Advisory",
        "color": "#BA55D3"
    },
    "coastalFloodAdvisory": {
        "name": "Coastal Flood Advisory",
        "color": "#7CFC00"
    },
    "lakeshoreFloodAdvisory": {
        "name": "Lakeshore Flood Advisory",
        "color": "#7CFC00"
    },
    "hydrologicAdvisory": {
        "name": "Hydrologic Advisory",
        "color": "#00FF7F"
    },
    "floodAdvisory": {
        "name": "Flood Advisory",
        "color": "#00FF7F"
    },
    "arroyoandSmallStreamFloodAdvisory": {
        "name": "Arroyo and Small Stream Flood Advisory",
        "color": "#00FF7F"
    },
    "smallStreamFloodAdvisory": {
        "name": "Small Stream Flood Advisory",
        "color": "#00FF7F"
    },
    "urbanandSmallStreamFloodAdvisory": {
        "name": "Urban and Small Stream Flood Advisory",
        "color": "#00FF7F"
    },
    "heatAdvisory": {
        "name": "Heat Advisory",
        "color": "#FF7F50"
    },
    "windChillAdvisory": {
        "name": "Wind Chill Advisory",
        "color": "#AFEEEE"
    },
    "lakeEffectSnowAdvisory": {
        "name": "Lake Effect Snow Advisory",
        "color": "#48D1CC"
    },
    "winterWeatherAdvisory": {
        "name": "Winter Weather Advisory",
        "color": "#7B68EE"
    },
    "freezingRainAdvisory": {
        "name": "Freezing Rain Advisory",
        "color": "#DA70D6"
    },
    "avalancheAdvisory": {
        "name": "Avalanche Advisory",
        "color": "#CD853F"
    },
    "tropicalDepressionLocalStatement": {
        "name": "Tropical Depression Local Statement",
        "color": "#FFE4B5"
    },
    "tropicalStormLocalStatement": {
        "name": "Tropical Storm Local Statement",
        "color": "#FFE4B5"
    },
    "typhoonLocalStatement": {
        "name": "Typhoon Local Statement",
        "color": "#FFE4B5"
    },
    "hurricaneLocalStatement": {
        "name": "Hurricane Local Statement",
        "color": "#FFE4B5"
    },
    "stormWatch": {
        "name": "Storm Watch",
        "color": "#FFE4B5"
    },
    "tropicalStormWatch": {
        "name": "Tropical Storm Watch",
        "color": "#F08080"
    },
    "typhoonWatch": {
        "name": "Typhoon Watch",
        "color": "#FF00FF"
    },
    "hurricaneForceWindWatch": {
        "name": "Hurricane Force Wind Watch",
        "color": "#9932CC"
    },
    "hurricaneWatch": {
        "name": "Hurricane Watch",
        "color": "#FF00FF"
    },
    "stormSurgeWatch": {
        "name": "Storm Surge Watch",
        "color": "#DB7FF7"
    },
    "redFlagWarning": {
        "name": "Red Flag Warning",
        "color": "#FF1493"
    },
    "freezeWarning": {
        "name": "Freeze Warning",
        "color": "#483D8B"
    },
    "hardFreezeWarning": {
        "name": "Hard Freeze Warning",
        "color": "#9400D3"
    },
    "extremeColdWarning": {
        "name": "Extreme Cold Warning",
        "color": "#0000FF"
    },
    "windChillWarning": {
        "name": "Wind Chill Warning",
        "color": "#B0C4DE"
    },
    "floodStatement": {
        "name": "Flood Statement",
        "color": "#00FF00"
    },
    "galeWarning": {
        "name": "Gale Warning",
        "color": "#DDA0DD"
    },
    "flashFloodWatch": {
        "name": "Flash Flood Watch",
        "color": "#2E8B57"
    },
    "severeThunderstormWatch": {
        "name": "Severe Thunderstorm Watch",
        "color": "#DB7093"
    },
    "tornadoWatch": {
        "name": "Tornado Watch",
        "color": "#FFFF00"
    },
    "excessiveHeatWarning": {
        "name": "Excessive Heat Warning",
        "color": "#C71585"
    },
    "lakeEffectSnowWarning": {
        "name": "Lake Effect Snow Warning",
        "color": "#008B8B"
    },
    "blowingDustWarning": {
        "name": "Blowing Dust Warning",
        "color": "#FFE4C4"
    },
    "dustStormWarning": {
        "name": "Dust Storm Warning",
        "color": "#FFE4C4"
    },
    "highSurfWarning": {
        "name": "High Surf Warning",
        "color": "#228B22"
    },
    "floodWarning": {
        "name": "Flood Warning",
        "color": "#00FF00"
    },
    "lakeshoreFloodWarning": {
        "name": "Lakeshore Flood Warning",
        "color": "#228B22"
    },
    "coastalFloodWarning": {
        "name": "Coastal Flood Warning",
        "color": "#228B22"
    },
    "ashfallWarning": {
        "name": "Ashfall Warning",
        "color": "#A9A9A9"
    },
    "volcanoWarning": {
        "name": "Volcano Warning",
        "color": "#2F4F4F"
    },
    "earthquakeWarning": {
        "name": "Earthquake Warning",
        "color": "#8B4513"
    },
    "avalancheWarning": {
        "name": "Avalanche Warning",
        "color": "#1E90FF"
    },
    "tsunamiWatch": {
        "name": "Tsunami Watch",
        "color": "#FF00FF"
    },
    "tsunamiAdvisory": {
        "name": "Tsunami Advisory",
        "color": "#D2691E"
    },
    "stormWarning": {
        "name": "Storm Warning",
        "color": "#9400D3"
    },
    "tropicalStormWarning": {
        "name": "Tropical Storm Warning",
        "color": "#B22222"
    },
    "highWindWarning": {
        "name": "High Wind Warning",
        "color": "#DAA520"
    },
    "winterStormWarning": {
        "name": "Winter Storm Warning",
        "color": "#FF69B4"
    },
    "iceStormWarning": {
        "name": "Ice Storm Warning",
        "color": "#8B008B"
    },
    "snowSquallWarning": {
        "name": "Snow Squall Warning",
        "color": "#C71585"
    },
    "blizzardWarning": {
        "name": "Blizzard Warning",
        "color": "#FF4500"
    },
    "specialMarineWarning": {
        "name": "Special Marine Warning",
        "color": "#FFA500"
    },
    "typhoonWarning": {
        "name": "Typhoon Warning",
        "color": "#DC143C"
    },
    "hurricaneWarning": {
        "name": "Hurricane Warning",
        "color": "#DC143C"
    },
    "hurricaneForceWindWarning": {
        "name": "Hurricane Force Wind Warning",
        "color": "#CD5C5C"
    },
    "stormSurgeWarning": {
        "name": "Storm Surge Warning",
        "color": "#B524F7"
    },
    "lawEnforcementWarning": {
        "name": "Law Enforcement Warning",
        "color": "#C0C0C0"
    },
    "civilEmergencyMessage": {
        "name": "Civil Emergency Message",
        "color": "#FFB6C1"
    },
    "blueAlert": {
        "name": "Blue Alert",
        "color": "#FFFFFF"
    },
    "fireWarning": {
        "name": "Fire Warning",
        "color": "#A0522D"
    },
    "hazardousMaterialsWarning": {
        "name": "Hazardous Materials Warning",
        "color": "#4B0082"
    },
    "radiologicalHazardWarning": {
        "name": "Radiological Hazard Warning",
        "color": "#4B0082"
    },
    "nuclearPowerPlantWarning": {
        "name": "Nuclear Power Plant Warning",
        "color": "#4B0082"
    },
    "civilDangerWarning": {
        "name": "Civil Danger Warning",
        "color": "#FFB6C1"
    },
    "evacuation-Immediate": {
        "name": "Evacuation - Immediate",
        "color": "#7FFF00"
    },
    "shelterInPlaceWarning": {
        "name": "Shelter In Place Warning",
        "color": "#FA8072"
    },
    "severeWeatherStatement": {
        "name": "Severe Weather Statement",
        "color": "#00FFFF"
    },
    "flashFloodStatement": {
        "name": "Flash Flood Statement",
        "color": "#8B0000"
    },
    "flashFloodWarning": {
        "name": "Flash Flood Warning",
        "color": "#8B0000"
    },
    "severeThunderstormWarning": {
        "name": "Severe Thunderstorm Warning",
        "color": "#FFA500"
    },
    "extremeWindWarning": {
        "name": "Extreme Wind Warning",
        "color": "#FF8C00"
    },
    "tornadoWarning": {
        "name": "Tornado Warning",
        "color": "#FF0000"
    },
    "tsunamiWarning": {
        "name": "Tsunami Warning",
        "color": "#FD6347"
    },
    "tropicalCycloneStatement": { // NOTE: This key is not defined in our style/legend. It was found during testing. It is currently set to Tropical Storm Warning's color but we need to figure our the correct color, and also add it to legends if it is not there
        "name": "Tropical Cyclone Statement",
        "color": "#FFE4B5"
    },
    "tropicalCycloneStatement": {
        "name": "Tropical Cyclone Local Statement",
        "color": "#FFE4B5"
    }
}

//Weather Forecast layers; add objects for new layers here
export const ZONE_FORECAST_LAYERS = {
    beach_forecasts_day1: {
        "id" : "0",
        "label" : "Beach and Surf Zones - Today",
        "group" : "beach",
        "legend_img" : { "Low" : "0/images/fe58ae10fbdde6b48b18eafb5a0e26c0",
            "Moderate" : "0/images/712fb8c9e875ef390ae87f8583659e50",
            "High" : "0/images/135a784c15dd3c8f12f4f3e590f6b93b",
            "NotProvided" : "0/images/8330e401502c8831f1e570b975598ddb"
        },
        "abstract" : "The Beach and Surf Zone Forecasts provide geo-referenced links to NWS forecasts for specific beach areas for the next 2 days. The text forecasts are usually issued by Weather Forecast Offices (WFOs) at least once per day on a seasonal basis, but can be issued year-round."
    },
    beach_forecasts_day2: {
        "id" : "1",
        "label" : "Beach and Surf Zones - Tomorrow",
        "group" : "beach"
    },
    great_lakes_beach_forecasts_day1: {
        "id" : "2",
        "label" : "Great Lakes Beach and Surf Zones - Today",
        "group" : "beach"
    },
    great_lakes_beach_forecasts_day2: {
        "id" : "3",
        "label" : "Great Lakes Beach and Surf Zones - Tomorrow",
        "group" : "beach"
    },
    coastal_marine_zone_forecasts: {
        "id" : "5",
        "label" : "Coastal & Great Lakes Waters",
        "group" : "marine",
        "color" : "#1a6ed9",
        "legend_img" : "5/images/b24b0418238c20c9712baf9a3ca4f6b8",
        "abstract" : "The Coastal Waters and Great Lakes Forecasts provide geo-referenced links to NWS marine weather forecasts for 5 days for coastal waters and also the nearshore and open lake areas of the Great Lakes issued by the WFOs."
    },
    offshore_zone_forecasts: {
        "id" : "6",
        "label" : "Offshore Waters",
        "group" : "marine",
        "color" : "#21bce5",
        "legend_img" : "6/images/4df78d3c79e9ca4338aacda30d2b26e9",
        "abstract" : "The Offshore Waters Forecasts provide geo-referenced links to NWS offshore marine weather forecasts for 5 days issued by NCEP Centers (NHC, OPC), WFOs in AK and WFO Honolulu, HI."
    },
    high_seas_zone_forecasts: {
        "id" : "10",
        "label" : "High Seas Areas",
        "group" : "marine",
        "color" : "#61d727",
        "legend_img" : "10/images/02da3c901d24ced76b3f3b3248a9c191",
        "abstract" : "The High Seas Forecasts provide geo-referenced links to NWS high seas marine weather forecasts for 48 hours for the high seas issued by NHC, OPC, and WFO Honolulu."
    },
    public_weather_zones: {
        "id" : "8",
        "label" : "Public Weather Zones",
        "group" : "inland",
        "color" : "#df5d21",
        "legend_img" : "8/images/bdc01fb070cd6e145760c7913d37420d",
        "abstract" : "The Public Weather Zone Forecasts include links to the latest NWS surface weather forecasts for the next 7 days usually at the county-scale or sub-county scale."
    },
    fire_weather_zones: {
        "id" : "9",
        "label" : "Fire Weather Zones",
        "group" : "inland",
        "color" : "#ecad29",
        "legend_img" : "9/images/6f4b652f21163e15cbd88dbdcfdd4337",
        "abstract" : "The Fire Weather Zone Forecasts include links to NWS web pages posting the latest zone-type forecast providing the average fire weather conditions across the zone for the next few days."
    }
}

export const RIP_CURRENT_COLORS = {
    "High" : "#e40404",
    "Moderate" : "#fbf844",
    "Low" : "#e4e4e4",
    "Not Provided" : "#cdcdcd"
}

//federal boundary layers; add objects for new layers here
export const ZONE_LAYERS = {
    cwa_boundaries: {
        "id" : "1",
        "type" : "external",
        "label" : "County Warning Areas",
        "color" : "#ea82d3",
        "legend_img" : "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/MapServer/1/images/ccf4f6dc2eb3ee12cfaa79fddf46693b",
        "abstract" : "The County Warning Area (CWA) boundaries depict the geographic areas of responsibility of the 122 NWS Weather Forecast Offices (WFO). The WFOs have the responsibility for issuing public, marine, fire, and aviation weather forecasts and advisories, watches, and warnings. The CWA covers land areas and in the case of coastal WFOs also covers coastal waters and for the Great Lakes WFOs, covers the nearshore and open lake waters."
    },
    river_forcast_centers: {
        "id" : "11",
        "type" : "external",
        "label" : "River Forecast Center Areas",
        "color" : "#c31116",
        "legend_img" : "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/MapServer/11/images/230d43d1ef3eeb5b69dbbfdc6f05c22e",
        "abstract" : "The River Forecast Centers boundaries depict the geographic areas of responsibility of the 13 NWS River Forecast Centers (RFC). The RFCs serve groups of NWS WFOs as well as partners by providing quantitative precipitation analyses and forecasts, river forecast model guidance, and river forecasts to assess flood potential, as well as other basic hydrologic information."
    },
    military_boundaries: {
        "id" : "0",
        "type" : "internal",
        "label" : "DOD Military Working Areas",
        "color" : "#000000",
        "abstract" : "The DOD Military Working Areas depict the boundaries of the VACAPES and Cherry Point Range Complex Operating Areas (OPAREA) in the Mid-Atlantic Region along with associated Special Use Airspace (SUA) in Maryland, Virginia, West Virginia, and North Carolina. The working areas, which include airspace, seaspace, and undersea space, provide a safe and realistic training and testing environment for U.S. military personnel."
    }
}

export const CONVECTIVE_LAYERS = {
    categorical_day1: {
        "id" : "25",
        "label" : "Categorical Outlook",
        "arc_id" : "1",
        "day" : "1",
        "legend_text" : "Risk Levels",
        "day_group" : "1",
        "layer_name" : "categorical_day1",
        "hazard_group": "Categorical Outlook"
    },
    significant_tornado_day1: {
        "id" : "24",
        "label" : "Significant Tornado Outlook",
        "arc_id" : "2",
        "day" : "1",
        "legend_text" : "10%+ probability of EF2-EF5 tornadoes within 25 mi of a point",
        "day_group" : "1",
        "layer_name" : "significant_tornado_day1",
        "hazard_group": "Tornado Outlook"
    },
    probabilistic_tornado_day1: {
        "id" : "23",
        "label" : "Probabilistic Tornado Outlook",
        "arc_id" : "3",
        "day" : "1",
        "legend_text" : "Probability of a tornado within 25 mi of a point",
        "day_group" : "1",
        "layer_name" : "probabilistic_tornado_day1",
        "hazard_group": "Tornado Outlook"
    },
    significant_hail_day1: {
        "id" : "22",
        "label" : "Significant Hail Outlook",
        "arc_id" : "4",
        "day" : "1",
        "legend_text" : "10%+ probability of 2-in+ diameter hail within 25 mi of a point",
        "day_group" : "1",
        "layer_name" : "significant_hail_day1",
        "hazard_group": "Hail Outlook"
    },
    probabilistic_hail_day1: {
        "id" : "21",
        "label" : "Probabilistic Hail Outlook",
        "arc_id" : "5",
        "day" : "1",
        "legend_text" : "Probability of 1-in+ diameter hail within 25 mi of a point",
        "day_group" : "1",
        "layer_name" : "probabilistic_hail_day1",
        "hazard_group": "Hail Outlook"
    },
    significant_wind_day1: {
        "id" : "20",
        "label" : "Significant Wind Outlook",
        "arc_id" : "6",
        "day" : "1",
        "legend_text" : "10%+ probability of wind gusts 65+ kts within 25 mi of a point",
        "day_group" : "1",
        "layer_name" : "significant_wind_day1",
        "hazard_group": "Wind Outlook"
    },
    probabilistic_wind_day1: {
        "id" : "19",
        "label" : "Probabilistic Wind Outlook",
        "arc_id" : "7",
        "day" : "1",
        "legend_text" : "Probability of damaging thunderstorm winds or gusts of 50+ kts within 25 mi of a point",
        "day_group" : "1",
        "layer_name" : "probabilistic_wind_day1",
        "hazard_group": "Wind Outlook"
    },
    categorical_day2: {
        "id" : "17",
        "label" : "Categorical Outlook",
        "arc_id" : "9",
        "day" : "2",
        "legend_text" : "Risk Levels",
        "day_group" : "2",
        "layer_name" : "categorical_day2",
        "hazard_group": "Categorical Outlook"
    },
    significant_tornado_day2: {
        "id" : "16",
        "label" : "Significant Tornado Outlook",
        "arc_id" : "10",
        "day" : "2",
        "legend_text" : "10%+ probability of EF2-EF5 tornadoes within 25 mi of a point",
        "day_group" : "2",
        "layer_name" : "significant_tornado_day2",
        "hazard_group": "Tornado Outlook"
    },
    probabilistic_tornado_day2: {
        "id" : "15",
        "label" : "Probabilistic Tornado Outlook",
        "arc_id" : "11",
        "day" : "2",
        "legend_text" : "Probability of a tornado within 25 mi of a point",
        "day_group" : "2",
        "layer_name" : "probabilistic_tornado_day2",
        "hazard_group": "Tornado Outlook"
    },
    significant_hail_day2: {
        "id" : "14",
        "label" : "Significant Hail Outlook",
        "arc_id" : "12",
        "day" : "2",
        "legend_text" : "10%+ probability of 2-in+ diameter hail within 25 mi of a point",
        "day_group" : "2",
        "layer_name" : "significant_hail_day2",
        "hazard_group": "Hail Outlook"
    },
    probabilistic_hail_day2: {
        "id" : "13",
        "label" : "Probabilistic Hail Outlook",
        "arc_id" : "13",
        "day" : "2",
        "legend_text" : "Probability of 1-in+ diameter hail within 25 mi of a point",
        "day_group" : "2",
        "layer_name" : "probabilistic_hail_day2",
        "hazard_group": "Hail Outlook"
    },
    significant_wind_day2: {
        "id" : "12",
        "label" : "Significant Wind Outlook",
        "arc_id" : "14",
        "day" : "2",
        "legend_text" : "10%+ probability of wind gusts 65 kts+ within 25 mi of a point",
        "day_group" : "2",
        "layer_name" : "significant_wind_day2",
        "hazard_group": "Wind Outlook"
    },
    probabilistic_wind_day2: {
        "id" : "11",
        "label" : "Probabilistic Wind Outlook",
        "arc_id" : "15",
        "day" : "2",
        "legend_text" : "Probability of damaging thunderstorm winds or gusts of 50+ kts within 25 mi of a point",
        "day_group" : "2",
        "layer_name" : "probabilistic_wind_day2",
        "hazard_group": "Wind Outlook"
    },
    categorical_day3: {
        "id" : "9",
        "label" : "Categorical Outlook",
        "arc_id" : "17",
        "day" : "3",
        "legend_text" : "Risk Levels",
        "day_group" : "3",
        "layer_name" : "categorical_day3",
        "specific_label" : "Categorical Outlook",
        "hazard_group": "Categorical Outlook"
    },
    significant_severe_day3: {
        "id" : "8",
        "label" : "Significant Severe Weather Outlook",
        "arc_id" : "18",
        "day" : "3",
        "legend_text" : "10%+ probability of significant severe weather within 25 mi of a point",
        "day_group" : "3",
        "layer_name" : "significant_severe_day3",
        "specific_label" : "Significant Severe Weather Outlook",
        "hazard_group": "Severe Weather Outlook"
    },
    probabilistic_severe_day3: {
        "id" : "7",
        "label" : "Day 3 Probabilistic Severe Weather Outlook",
        "arc_id" : "19",
        "day" : "3",
        "legend_text" : "Probability of severe weather within 25 mi of a point",
        "day_group" : "3",
        "layer_name" : "probabilistic_severe_day3",
        "specific_label" : "Probabilistic Severe Weather Outlook",
        "hazard_group": "Severe Weather Outlook"
    },
    probabilistic_severe_day4: {
        "id" : "5",
        "label" : "Day 4-8 Probabilistic Severe Weather Outlook",
        "arc_id" : "21",
        "day" : "4",
        "legend_text" : "A depicted severe weather area indicates 15% or 30%+ probability for severe thunderstorms within 25 mi of a point",
        "day_group" : "4thru8",
        "layer_name" : "probabilistic_severe_day4",
        "specific_label" : "Day 4 Severe Weather Outlook"
    },
    probabilistic_severe_day5: {
        "id" : "4",
        "label" : "Day 4-8 Probabilistic Severe Weather Outlook",
        "arc_id" : "22",
        "day" : "5",
        "legend_text" : "A depicted severe weather area indicates 15% or 30%+ probability for severe thunderstorms within 25 mi of a point",
        "day_group" : "4thru8",
        "layer_name" : "probabilistic_severe_day5",
        "specific_label" : "Day 5 Severe Weather Outlook"
    },
    probabilistic_severe_day6: {
        "id" : "3",
        "label" : "Day 4-8 Probabilistic Severe Weather Outlook",
        "arc_id" : "23",
        "day" : "6",
        "legend_text" : "A depicted severe weather area indicates 15% or 30%+ probability for severe thunderstorms within 25 mi of a point",
        "day_group" : "4thru8",
        "layer_name" : "probabilistic_severe_day6",
        "specific_label" : "Day 6 Severe Weather Outlook"
    },
    probabilistic_severe_day7: {
        "id" : "2",
        "label" : "Day 4-8 Probabilistic Severe Weather Outlook",
        "arc_id" : "24",
        "day" : "7",
        "legend_text" : "A depicted severe weather area indicates 15% or 30%+ probability for severe thunderstorms within 25 mi of a point",
        "day_group" : "4thru8",
        "layer_name" : "probabilistic_severe_day7",
        "specific_label" : "Day 7 Severe Weather Outlook"
    },
    probabilistic_severe_day8: {
        "id" : "1",
        "label" : "Day 4-8 Probabilistic Severe Weather Outlook",
        "arc_id" : "25",
        "day" : "8",
        "legend_text" : "A depicted severe weather area indicates 15% or 30%+ probability for severe thunderstorms within 25 mi of a point",
        "day_group" : "4thru8",
        "layer_name" : "probabilistic_severe_day8",
        "specific_label" : "Day 8 Severe Weather Outlook"
    }
};

export const S100_PRODUCT_INFO = {
    s100_general_coverage: {
        "color" : "#969696",
    },
    s111_approach_coverage: {
        "color" : "#bfb02d",
    },
    s102_approach_coverage: {
        "color" : "#54bfc7",
    },
    s100_harbor_coverage: {
        "color" : "#e31906",
    }
}

// Hard coded from tropical_storm_surge_inundation.sld / tropical_storm_surge_tidalmask.sld
// Note: This is a layer where theres one service and multiple layers (like our original s111 implementation)
// But unlike s111 there are different SLD's for each layer, so from the perspective of feature info
// its almost like 2 separate layers, but they both used the same getFeatureInfo URL... Luckily, inundation
// SLD is a subset of tidalmask SLD, so We are able to just display one feature object with values from
// this object, which essentially merges the two SLD files
export const TROPICAL_SS_FEATURES = {
    "0": {color: "#ffffff", label: "Less than 1 foot above ground"}, // This is not from either SLD - but 0 is returned when there's no data so rather than protect against looking up with "0", add 0 -> <no data message> to the object (also note color #ffffff is just filler)
    "2": {color: "#0071fe", label: "Greater than 1 foot above ground"},
    "3": {color: "#ffff00", label: "Greater than 3 feet above ground"},
    "4": {color: "#ffaa01", label: "Greater than 6 feet above ground"},
    "5": {color: "#fe0000", label: "Greater than 9 feet above ground"},
    "7": {color: "#000000", label: "Levee Area - Consult local officials for flood risk"},
    "15": {color: "#9c9c9c", label: "Intertidal Zone / Estuarine Wetland"},
}

export const METAR_CLOUDS = [
    '10.',
    'OVC',
    '8.8',
    '7.5',
    'BKN',
    'VV',
    '6.3',
    '5.0',
    '3.7',
    '2.5',
    'SCT',
    'SKT',
    '1.2',
    'FEW',
    '0.0',
    'SKC',
    'CLR'
]

export const METAR_WEATHER = [
    "+",
    "-",
    "BC",
    "BL",
    "BR",
    "DR",
    "DS",
    "DU",
    "DZ",
    "FC",
    "FG",
    "FU",
    "FZ",
    "GR",
    "GS",
    "HZ",
    "IC",
    "MI",
    "PE",
    "PL",
    "PO",
    "PR",
    "PY",
    "RA",
    "SA",
    "SG",
    "SH",
    "SN",
    "SQ",
    "SS",
    "TS",
    "UP",
    "VA",
    "VC"
]

export const METAR_WEATHER_TEXT = {
    "-": "light intensity",
    "+": "heavy intensity",
    "BC": "patches",
    "BL": "blowing",
    "BR": "mist",
    "DR": "low drifting",
    "DS": "duststorm",
    "DU": "widespread dust",
    "DZ": "drizzle",
    "FC": "funnel cloud",
    "FG": "fog",
    "FU": "smoke",
    "FZ": "freezing",
    "GR": "hail",
    "GS": "small hail",
    "HZ": "haze",
    "IC": "ice crystals",
    "MI": "shallow",
    "PE": "ice pellets",
    "PL": "ice pellets",
    "PO": "dust or sand whirls",
    "PR": "partial",
    "PY": "spray",
    "RA": "rain",
    "SA": "sand",
    "SG": "snow grains",
    "SH": "showers",
    "SN": "snow",
    "SQ": "squall",
    "SS": "sandstorm",
    "TS": "thunderstorm",
    "UP": "unknown precipitation",
    "VA": "volcanic ash",
    "VC": "in the vicinity",
    "VIRGA": "virga"
};

// TODO: to better match nowcoast@idp/NHC's wishes, no data clicks for storm surge should
// display a big grey box with text like, "Potential Storm Surge Flooding Map not active at this time and location"
// But is it for 0 values or for no features returned. Its ambiguous, clicking on a section of the map
// with no color for tropical_ss could do either (return a feature with a 0 value, or no feature at all)
// need to verify behavior with john. We may want popup for both cases