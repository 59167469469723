import React from 'react';
import { Typography, TableContainer, Table, TableRow, TableCell, TableBody } from '@mui/material';
import FeatureInfoAccordion from './feature-info-accordion';
import SurfaceObsLegend from '../menu/legend-menu-items/surface-obs-legend.js';
import { parseObsTimestamp, rangeDateString } from '../../utilities/utilities.js';
import { WaterLevelChart, GreatLakesCharts } from '../../utilities/chart.js';

const sxStyles = {
    tableCell: {
        fontSize: '0.8em',
    },
    fieldCell: {
        fontSize: '0.8em',
        minWidth: 175
    },
    fieldTitle: {
        fontSize: {
            md: '1em',
            xs: '0.8em'
        }
    },
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    }
};

const classes ={
    FeatureInfoContainer: {
        padding: '10px'
    },
    LegendContainer: {
        paddingTop: '0.5em'
    }
};

const dateFormat = {
    dateStyle: "medium",
    timeStyle: "long"
};

const cloudCoverText = {
    "10." : "Overcast",
    "OVC" : "Overcast",
    "8.8" : "7/8 Sky Covered",
    "BKN" : "Mostly Cloudy",
    "7.5" : "Mostly Cloudy",
    "6.3" : "5/8 Sky Covered",
    "5.0" : "Partly Cloudy",
    "3.7" : "3/8 Sky Covered",
    "2.5" : "Scattered Clouds",
    "SCT" : "Scattered Clouds",
    "1.2" : "Few Clouds",
    "FEW" : "Few Clouds",
    "0.0" : "Clear",
    "SKC" : "Clear",
    "CLR" : "Clear",
    "VV" : "Vertical Visibility"
}

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) data - json response obj from getFeatureInfo request
**/
export default function SurfaceObsFeatureInfo(props) {

    if (props.data[0] === "no data") {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data was found for this location.</Typography>
            </div>
        );
    }

    const obsStations =  getStations('surface_obs', props.data);
    const COOPSStations = getStations('co_ops_stations', props.data);

    let obsFeatureInfo = [...obsStations].map((station,index) => {
        if(props.data.length === 1) {
            return getObsStationTable(station, index);
        } else {
            return (
                <FeatureInfoAccordion key={index}
                    featureName={station.getProperties()['location'] ? station.getId() + " - " + station.getProperties()['location'] : station.getId()}
                    defaultExpanded={false}
                    styleOverride={{ color: 'rgba(76, 125, 197, 1)' }}
                >
                    {getObsStationTable(station, index)}
                </FeatureInfoAccordion>
            );
        }
    });

    let COOPSFeatureInfo = [...COOPSStations].map((station,index) => {
        if(props.data.length === 1) {
            return (
                <FeatureInfoAccordion key={index}
                    featureName={`${station.getId()} - ${station.getProperties()['Name']} Station`}
                    defaultExpanded={true}
                    styleOverride={{ color: 'rgba(0, 0, 128, 1)' }}
                >
                    {getCOOPSStationTable(station, index)}
                </FeatureInfoAccordion>
            );
        } else {
            return (
                <FeatureInfoAccordion key={index}
                    featureName={`${station.getId()} - ${station.getProperties()['Name']} Station`}
                    defaultExpanded={false}
                    styleOverride={{ color: 'rgba(0, 0, 128, 1)' }}
                >
                    {getCOOPSStationTable(station, index)}
                </FeatureInfoAccordion>
            );
        }
    });

    return(
        <div style={classes.FeatureInfoContainer}>
            {obsStations.length ? <Typography align="center" sx={{'paddingBottom': '5px', 'fontWeight': 'bold'}}>Surface & Marine Weather Observations</Typography> : null}
            {obsFeatureInfo}
            {COOPSStations.length ? <Typography align="center" sx={{'paddingBottom': '5px', 'fontWeight': 'bold', 'paddingTop': '5px'}}>Coastal & Lakeshore Water Level Observations</Typography> : null}
            {COOPSFeatureInfo}
            {obsStations.length ?
                <div style={classes.LegendContainer} align="center">
                    <SurfaceObsLegend/>
                </div>
            : null}
        </div>
    );
}

function getCOOPSStationTable(station, index, datumSelect, setDatum, handleChange) {
    const dataHead = "https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?"
    const dataStandardArgs = [
        'application=nowCOAST',
        'format=json',
        'units=english',
        'time_zone=gmt',
        //'date=recent',
        ]
    const dataStandardArgsString = dataStandardArgs.join('&')

    const dataFormats = {
        coops_wtr_active: {
            queries: {
                products: ['water_level', 'predictions'],
            }
        },
    }

    let affiliation = station.getProperties()['Affil'];
    let state = ", " + station.getProperties()['State'];
    if(station.getProperties()['Affil'].includes(',')) {
        const affiliation_list = affiliation.split(',');
        affiliation = affiliation_list.join(', ');
    }

    if(station.getProperties()['State'] === 'None') {
        state = ''
    }

    const chartQueries = {}
    let stationArg = 'station=' + station.getId()
    let tidal = station.getProperties()['tidal']
    let greatLakes = station.getProperties()['greatlakes']

    let currentDate = new Date();
    let dateEnd = rangeDateString(currentDate)
    let dateBegin = rangeDateString(new Date().setDate(currentDate.getDate() -3))
    let dateRangeArg = `begin_date=${dateBegin}&end_date=${dateEnd}`
    let dateRangeLinkArg = `&bdate=${dateBegin}&edate=${dateEnd}`
    const dataLink = station.getProperties()['Data'] + dateRangeLinkArg

    if (tidal === true || greatLakes === false) {
        for (const i in dataFormats['coops_wtr_active'].queries.products) {
            let productName = dataFormats['coops_wtr_active'].queries.products[i]
            let productArg = 'product=' + productName
            const args = [stationArg, productArg, dateRangeArg, dataStandardArgsString]
            let argString = args.join('&')
            let query = dataHead + argString
            chartQueries[productName] = query
        }
    } else if (greatLakes === true) {
        const lakeDatums = ['LWD', 'IGLD']
        for (const i in lakeDatums) {
            let productName = dataFormats['coops_wtr_active'].queries.products[0]
            let productArg = 'product=' + productName
            let datumArg = 'datum=' + lakeDatums[i]
            const args = [stationArg, productArg, datumArg, dateRangeArg, dataStandardArgsString]
            let argString = args.join('&')
            let query = dataHead + argString
            chartQueries[lakeDatums[i]] = query
        }
    }

    //console.log(station.getId(), tidal, greatLakes, chartQueries)
    let waterChart = null

    if (tidal === true || greatLakes === false) {
        waterChart = <>
                <WaterLevelChart waterURL={chartQueries['water_level']} predURL={chartQueries['predictions']} datums={station.getProperties()['datums']}/>
            </>
    } else {
        waterChart = <>
                <GreatLakesCharts waterURL={chartQueries['LWD']} datum={'LWD'}/>
                <br />
                <GreatLakesCharts waterURL={chartQueries['IGLD']} datum={'IGLD'}/>
            </>
    }

    return (
        <div key={index} style={{padding: '5px 0px 10px 0px', height: 'fit-content'}}>
            <Typography align="center" sx={{mt: "10px", ...sxStyles.fieldTitle}} >NOAA/National Ocean Service</Typography>
            <Typography align="center" sx={sxStyles.fieldTitle} >Water Levels at {station.getProperties()['Name'] + state}</Typography>
            <Typography align="center" sx={sxStyles.fieldTitle} >NOS Station ID: {station.getId()}</Typography>
            <Typography align='center' sx={{fontSize: '0.8em', ...sxStyles.links}} ><a href={dataLink} target="_blank" rel="noopener noreferrer">Data Link</a></Typography>
            {waterChart}
        </div>
    );
}

function getStations(layer, data) {
    return data.filter(station => {
        return station.getProperties()['layer'] === layer;
    })
}

function getObsStationTable(station, index) {
    let metarWeatherText = station.getProperties()['weather-text'];
    let metarClouds = station.getProperties()['metar-cloud'];

    return (
        <div key={index} style={{padding: '5px 0px 10px 0px'}}>
            <TableContainer key={index}>
                <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Station ID:</TableCell>
                        <TableCell align="left" sx={sxStyles.tableCell}>{station.getId()}</TableCell>
                    </TableRow>
                    { !station.getProperties()['location'] || station.getProperties()['location'] === "" ?
                        null :
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Station Name:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['location']}</TableCell>
                        </TableRow>
                    }
                    { station.getProperties()['tblname'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Station Type:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['tblname']}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['timeobs'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Observation Time:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(parseObsTimestamp(station.getProperties()['timeobs']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { metarClouds && metarClouds !== "null" && cloudCoverText[metarClouds] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Cloud Cover:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{cloudCoverText[metarClouds]}</TableCell>
                        </TableRow>
                        : null
                    }
                    { metarWeatherText && metarWeatherText.length > 0 ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Present Weather:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{metarWeatherText}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['temperature'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Air Temperature (&deg;F):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Math.round(parseFloat(station.getProperties()['temperature']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['visibility'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Visibility (mi):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Math.round(parseFloat(station.getProperties()['visibility']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['dewpoint'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Dew Point Temperature (&deg;F):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Math.round(parseFloat(station.getProperties()['dewpoint']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['sst'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Sea Surface Temperature (&deg;F):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Math.round(parseFloat(station.getProperties()['sst']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['waveheight'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Significant Wave Height (ft):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['waveheight'].toFixed(1)}</TableCell>
                        </TableRow>
                        : null
                    }
                    { (station.getProperties()['preschange'] || station.getProperties()['preschange'] === 0) && station.getProperties()['preschange'] !== 9999 ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>3-Hour Pressure Change (mb):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['preschange'].toFixed(1)}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['sealevelpress'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Mean Sea Level Pressure (mb):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['sealevelpress'].toFixed(1)}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['windspeed'] || station.getProperties()['windspeed'] === 0 ?
                        <>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Wind Speed:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['windspeed'].toFixed(1)} kts / {(station.getProperties()['windspeed'] * 1.15078).toFixed(1)} mph</TableCell>
                            </TableRow>
                        </>
                        : null
                    }
                    { station.getProperties()['windgust'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Wind Gust Speed:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['windgust'].toFixed(1)} kts / {(station.getProperties()['windgust'] * 1.15078).toFixed(1)} mph</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['winddir'] || station.getProperties()['winddir'] === 0?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Wind Direction:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{getCardinalFromDegrees(station.getProperties()['winddir'])}</TableCell>
                        </TableRow>
                        : null
                    }
                    { (Array.from(station.getId())[0] === 'K' || Array.from(station.getId())[0] === 'C' || Array.from(station.getId())[0] === 'M') && station.getProperties()['tblname'] !== 'MARITIME' ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Link:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>
                                <Typography variant='caption' sx={sxStyles.links}>
                                    <a href={"https://forecast.weather.gov/data/obhistory/" + station.getId() + ".html"}
                                    target="_blank" rel="noopener noreferrer">View Past Observations</a>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['tblname'] === 'MARITIME' && station.getId().length === 5 && (station.getId()[4] >= '0' && station.getId()[4] <= '9') ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Link:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>
                                <Typography variant='caption' sx={sxStyles.links}>
                                    <a href={"https://www.ndbc.noaa.gov/station_page.php?station=" + station.getId()}
                                    target="_blank" rel="noopener noreferrer">View Past Observations</a>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        : null
                    }
                </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

function getCardinalFromDegrees(angle) {
    if ((angle > 348.75 && angle <= 360 ) || (angle >= 0 && angle <= 11.25 )) {
        return "N";
    } else if (angle > 11.25 && angle <= 33.75) {
        return "NNE";
    } else if (angle > 33.75 && angle <= 56.25) {
        return "NE";
    } else if (angle > 56.25 && angle <= 78.75) {
        return "ENE";
    } else if (angle > 78.75 && angle <= 101.25 ) {
        return "E";
    } else if (angle > 101.25 && angle <= 123.75) {
        return "ESE";
    } else if (angle > 123.75 && angle <= 146.25) {
        return "SE";
    } else if (angle > 146.25 && angle <= 168.75 ) {
        return "SSE";
    } else if (angle > 168.75 && angle <= 191.25) {
        return "S";
    } else if (angle > 191.25 && angle <= 213.75) {
        return "SSW";
    } else if (angle > 213.75 && angle <= 236.25) {
        return "SW";
    } else if (angle > 236.25 && angle <= 258.75) {
        return "WSW";
    } else if (angle > 258.75 && angle <= 281.25) {
        return "W";
    } else if (angle > 281.25 && angle <= 303.75 ) {
        return "WNW";
    } else if (angle > 303.75 && angle <= 326.25) {
        return "NW";
    } else { // (angle > 326.25 && angle <= 348.75)
        return "NNW";
    }
}